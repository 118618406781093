import Markdown from "react-markdown"

const MarkDownConverter = (props) => {
    const {text} = props;

    const CustomUl = (props) => (
        <ul style={{ 
            listStyleType: 'disc', 
            paddingLeft: '0',
            whiteSpace: 'normal'
        }} {...props} />
    );
    
    const CustomLi = (props) => (
        <li style={{ marginLeft: '20px', marginBottom: '0' }} {...props} />
    );

    const CustomP = (props) => (
        <p className="h3" style={{ whiteSpace: 'normal' }} {...props}></p>
    )

    return (
        <Markdown
            components={{
                ul: CustomUl,
                li: CustomLi,
                p: CustomP,
            }}
        >{text}</Markdown>
    )
}

export default MarkDownConverter;