import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authReducer";
import testReducer from "./test/testReducer";


const store = configureStore({
    reducer: {
        auth: authReducer,
        test: testReducer,
    }
})

export default store