export const chatDataDrink = [
	{
		label: [
			'깔끔하고 도수 25도 이상인 술 찾아줄 수 있어?',
			'아마 전어회처럼 담백한 회를 먹을 것 같아. 술 맛은 너가 골라줘',
			'친구 생일 선물로 좋은 달달하고 2만원대 이하인 술 찾아줘',
			'아버지 생일 선물로 드릴 술 뭐가 있을까?',
		],
		text: [
			'깔끔하고 도수 25도 이상인 술 찾아줄 수 있어?',
			'아마 전어회처럼 담백한 회를 먹을 것 같아. 술 맛은 너가 골라줘',
			'친구 생일 선물로 좋은 달달하고 2만원대 이하인 술 찾아줘',
			'아버지 생일 선물로 드릴 술 뭐가 있을까?',
		]
	},
	{
		label: [
			'도수가 15도 이하인 술로 괜찮은 거 알려줘',
			'매실향이 좋은 술 3만원 밑으로 추천해줘',
			'복분자 들어 있는 술도 있어?',
			'매실향이 좋은 술 3만원 밑으로 추천해줘',
		],
		text: [
			'도수가 15도 이하인 술로 괜찮은 거 알려줘',
			'매실향이 좋은 술 3만원 밑으로 추천해줘',
			'복분자 들어 있는 술도 있어?',
			'매실향이 좋은 술 3만원 밑으로 추천해줘',
		],
	},
	{
		label: [
			'소주는 어떤 종류가 있어?',
			'청주랑 소주는 뭐가 달라?',
			'전통주에는 어떤 종류가 있는지 전체적으로 알려줘',
			'청주랑 소주는 뭐가 달라?',
		],
		text: [
			'소주는 어떤 종류가 있어?',
			'청주랑 소주는 뭐가 달라?',
			'전통주에는 어떤 종류가 있는지 전체적으로 알려줘',
			'청주랑 소주는 뭐가 달라?',
		],
	},
];

export const chatDataBook = [
	{
		label: [
			'재미있는 소설 추천해줘',
			'고전 소설을 좋아하는데 추천해줄 수 있어?',
			'오펜하이머 영화를 감명 깊게 봤어. 책도 있어?',
			'히가시노 게이고의 작품 추천해줘 ',
		],
		text: [
			'재미있는 소설 추천해줘',
			'고전 소설을 좋아하는데 추천해줄 수 있어?',
			'오펜하이머 영화를 감명 깊게 봤어. 책도 있어?',
			'히가시노 게이고의 작품 추천해줘 ',
		],
	},
	{
		label: [
			'출퇴근 할 때 가볍게 읽을 만 한 책 추천해줘',
			'미스터리 추리소설 하나 추천해줘!',
			'감동적인 내용의 한국 소설 찾아줘',
			'현대 사회에 대해서 생각해 볼 수 있는 책 추천해줘',
		],
		text: [
			'출퇴근 할 때 가볍게 읽을 만 한 책 추천해줘',
			'미스터리 추리소설 하나 추천해줘!',
			'감동적인 내용의 한국 소설 찾아줘',
			'현대 사회에 대해서 생각해 볼 수 있는 책 추천해줘',
		],
	},
	{
		label: [
			'오늘처럼 비가 오는 날에는 어떤 책을 읽는 것이 좋을까?',
			'요즘 사람들이 가장 많이 읽는 책 중에 추천해줘',
			'휴가 때 따스한 분위기의 책을 읽어 보고 싶어',
			'소소한 일상이 담긴 에세이를 읽으면서 힐링하고 싶어',
		],
		text: [
			'오늘처럼 비가 오는 날에는 어떤 책을 읽는 것이 좋을까?',
			'요즘 사람들이 가장 많이 읽는 책 중에 추천해줘',
			'휴가 때 따스한 분위기의 책을 읽어 보고 싶어',
			'소소한 일상이 담긴 에세이를 읽으면서 힐링하고 싶어',
		],
	},
];

export const chatDataCkat = [
	{
		label: [
			'렌지용 상품 추천해줘',
			'혼자 먹기 좋은 베스트 5개 추천해줘',
			'렌지용 상품 추천해줘',
			'혼자 먹기 좋은 베스트 5개 추천해줘',
			'음, 디저트가 먹고싶은데🤔',
		],
		text: [
			'렌지용 상품 추천해줘',
			'혼자 먹기 좋은 베스트 5개 추천해줘',
			'렌지용 상품 추천해줘',
			'혼자 먹기 좋은 베스트 5개 추천해줘',
			'음, 디저트가 먹고싶은데🤔',
		],
	},
	{
		label: [
			'다이어트 중이야. 인기있는 상품 추천 좀!',
			'간편하게 먹기 좋은 반찬 찾아줘',
			'오늘 저녁 한상 차려줘',
			'간편하게 먹기 좋은 반찬 찾아줘',
			'맥주와 함께 먹기 좋은 안주 추천 좀!',
		],
		text: [
			'다이어트 중이야. 인기있는 상품 추천 좀!',
			'간편하게 먹기 좋은 반찬 찾아줘',
			'오늘 저녁 한상 차려줘',
			'간편하게 먹기 좋은 반찬 찾아줘',
			'맥주와 함께 먹기 좋은 안주 추천 좀!',
		],
	},
	{
		label: [
			'음, 디저트가 먹고싶은데🤔',
			'맥주와 함께 먹기 좋은 안주 추천 좀!',
			'음, 디저트가 먹고싶은데🤔',
			'맥주와 함께 먹기 좋은 안주 추천 좀!',
			'오늘 저녁 한상 차려줘',
		],
		text: [
			'음, 디저트가 먹고싶은데🤔',
			'맥주와 함께 먹기 좋은 안주 추천 좀!',
			'음, 디저트가 먹고싶은데🤔',
			'맥주와 함께 먹기 좋은 안주 추천 좀!',
			'오늘 저녁 한상 차려줘',
		],
	},
];

export const chatDataFdko = [
	{
		label: [
			'소고기랑 같이 마실 와인 추천해줘!',
			'달달한 스파클링 와인 찾아줘',
			'오늘 피자 먹을건데 같이 편하게 마실 데일리 와인으로 뭐가 좋을까?',
			'소고기랑 같이 마실 와인 추천해줘!',
		],
		text: [
			'소고기랑 같이 마실 와인 추천해줘!',
			'달달한 스파클링 와인 찾아줘',
			'오늘 피자 먹을건데 같이 편하게 마실 데일리 와인으로 뭐가 좋을까?',
			'소고기랑 같이 마실 와인 추천해줘!',
		],
	},
	{
		label: [
			'요즘 인기 많은 와인들 어떤게 있는지 보여줘',
			'회랑 어울리는 화이트 와인 추천해줘',
			'달달하면서 부드러운 화이트 와인 찾아줘',
			'회랑 어울리는 화이트 와인 추천해줘',
		],
		text: [
			'요즘 인기 많은 와인들 어떤게 있는지 보여줘',
			'회랑 어울리는 화이트 와인 추천해줘',
			'달달하면서 부드러운 화이트 와인 찾아줘',
			'회랑 어울리는 화이트 와인 추천해줘',
		],
	},
	{
		label: [
			'부모님 생일 선물로 드릴 고급스러운 와인 추천해줘',
			'이탈리아에서 만들어진 와인 추천해줘',
			'살짝 시큼한 산도 좋은 와인 찾아줘',
			'10만원대에서 추천해줄 만한 와인 있어?',
		],
		text: [
			'부모님 생일 선물로 드릴 고급스러운 와인 추천해줘',
			'이탈리아에서 만들어진 와인 추천해줘',
			'살짝 시큼한 산도 좋은 와인 찾아줘',
			'10만원대에서 추천해줄 만한 와인 있어?',
		],
	},
];

export const chatDataDlst = [
	{
		label: [
			'10만원대 아일라 위스키 골라줘',
			'가성비 데일리 위스키 추천해줘',
			'바닐라나 꿀 향이 진한 버번 위스키 찾아줘',
			'10만원대 아일라 위스키 골라줘',
			'가성비 데일리 위스키 추천해줘',
		],
		text: [
			'10만원대 아일라 위스키 골라줘',
			'가성비 데일리 위스키 추천해줘',
			'바닐라나 꿀 향이 진한 버번 위스키 찾아줘',
			'10만원대 아일라 위스키 골라줘',
			'가성비 데일리 위스키 추천해줘',
		],
	},
	{
		label: [
			'소고기랑 같이 마실 와인 추천해줘!',
			'달달한 스파클링 와인 찾아줘',
			'달달한 스파클링 와인 찾아줘',
			'생선 요리랑 잘 어울리는 와인 있어?',
			'생선 요리랑 잘 어울리는 와인 있어?',
		],
		text: [
			'소고기랑 같이 마실 와인 추천해줘!',
			'달달한 스파클링 와인 찾아줘',
			'달달한 스파클링 와인 찾아줘',
			'생선 요리랑 잘 어울리는 와인 있어?',
			'생선 요리랑 잘 어울리는 와인 있어?',
		],
	},
	{
		label: [
			'맥캘란 찾아줘',
			'선물로 좋은 5만에서 10만원 정도 되는 와인 추천해줘',
			'부모님이 좋아하실 위스키 추천해줘',
			'선물로 좋은 5만에서 10만원 정도 되는 와인 추천해줘',
			'부모님이 좋아하실 위스키 추천해줘',
		],
		text: [
			'맥캘란 찾아줘',
			'선물로 좋은 5만에서 10만원 정도 되는 와인 추천해줘',
			'부모님이 좋아하실 위스키 추천해줘',
			'선물로 좋은 5만에서 10만원 정도 되는 와인 추천해줘',
			'부모님이 좋아하실 위스키 추천해줘',
		],
	},
];

export const chatDataBala = [
	{
		label: [
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
		],
		text: [
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
			'피로 개선에 좋은 영양제는 어떤 것들이 있나요?',
		],
	},
	{
		label: [
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
		],
		text: [
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
			'혈당 조절을 위해 먹으면 좋은 식품을 추천해주세요.',
		],
	},
	{
		label: [
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
		],
		text: [
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
			'수면과 스트레스 해소에 도움이 되는 제품을 알려주세요.',
		],
	},
];

export const chatDataNose = [
	{
		label: [
			'쿠쿠 전기밥솥 추천해줘.',
			'쿠쿠 전기밥솥 추천해줘.',
			'쿠쿠 전기밥솥 추천해줘.',
			'쿠쿠 전기밥솥 추천해줘.',
			'쿠쿠 전기밥솥 추천해줘.',
		],
		text: [
			'쿠쿠 전기밥솥 추천해줘.',
			'쿠쿠 전기밥솥 추천해줘.',
			'쿠쿠 전기밥솥 추천해줘.',
			'쿠쿠 전기밥솥 추천해줘.',
			'쿠쿠 전기밥솥 추천해줘.',
		],
	},
	{
		label: [
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
		],
		text: [
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
			'주로 현미밥을 해 먹는데 20만원 이하의 가성비 제품 찾아줘.',
		],
	},
	{
		label: [
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
		],
		text: [
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
			'저당 취사 가능한 밥솥 추천해 줄 수 있어?',
		],
	},
	{
		label: [
			'전기밥솥 살 때 고려할 점 알려줘.',
			'전기밥솥 살 때 고려할 점 알려줘.',
			'전기밥솥 살 때 고려할 점 알려줘.',
			'전기밥솥 살 때 고려할 점 알려줘.',
			'전기밥솥 살 때 고려할 점 알려줘.',
		],
		text: [
			'전기밥솥 살 때 고려할 점 알려줘.',
			'전기밥솥 살 때 고려할 점 알려줘.',
			'전기밥솥 살 때 고려할 점 알려줘.',
			'전기밥솥 살 때 고려할 점 알려줘.',
			'전기밥솥 살 때 고려할 점 알려줘.',
		],
	},
];

export const chatDataPick = [
	{
		label: [
			'5000원 이하 닭가슴살 추천해줘',
			'5000원 이하 닭가슴살 추천해줘',
			'5000원 이하 닭가슴살 추천해줘',
			'5000원 이하 닭가슴살 추천해줘',
			'5000원 이하 닭가슴살 추천해줘',
		],
		text: [
			'5000원 이하 닭가슴살 추천해줘',
			'5000원 이하 닭가슴살 추천해줘',
			'5000원 이하 닭가슴살 추천해줘',
			'5000원 이하 닭가슴살 추천해줘',
			'5000원 이하 닭가슴살 추천해줘',
		],
	},
	{
		label: [
			'간편한 다이어트 도시락 찾아줘',
			'간편한 다이어트 도시락 찾아줘',
			'간편한 다이어트 도시락 찾아줘',
			'간편한 다이어트 도시락 찾아줘',
			'간편한 다이어트 도시락 찾아줘',
		],
		text: [
			'간편한 다이어트 도시락 찾아줘',
			'간편한 다이어트 도시락 찾아줘',
			'간편한 다이어트 도시락 찾아줘',
			'간편한 다이어트 도시락 찾아줘',
			'간편한 다이어트 도시락 찾아줘',
		],
	},
	{
		label: [
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
		],
		text: [
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
			'식사대용 단백질 쉐이크 추천해 줄 수 있어?',
		],
	},
];

export const testDataFdko = [
	{
		label: [
			'위스키 입문',
			'드라이한 술',
			'위스키 입문',
			'드라이한 술',
			'위스키 입문',
		],
		text: [
			'위스키 입문',
			'드라이한 술',
			'위스키 입문',
			'드라이한 술',
			'위스키 입문',
		],
	},
	{
		label: [
			'선물용 술',
			'선물용 와인',
			'선물용 술',
			'선물용 와인',
			'선물용 술',
		],
		text: [
			'선물용 술',
			'선물용 와인',
			'선물용 술',
			'선물용 와인',
			'선물용 술',
		],
	},
	{
		label: [
			'과일맛 와인',
			'달달한 술',
			'과일맛 와인',
			'달달한 술',
			'과일맛 와인',
		],
		text: [
			'과일맛 와인',
			'달달한 술',
			'과일맛 와인',
			'달달한 술',
			'과일맛 와인',
		],
	}
]

export const chatDataCkatShort = [
	{
		label: [
			'달달한 디저트',
			'매콤한 반찬',
			'다양한 반찬',
		],
		text: [
			'달달한 디저트',
			'매콤한 반찬',
			'다양한 반찬',
		],
	},
	{
		label: [
			'바싹한 튀김',
			'필수 분식템',
			'마라 중식',
		],
		text: [
			'바싹한 튀김',
			'필수 분식템',
			'마라 중식',
		],
	},
	{
		label: [
			'해장에 좋은',
			'얼큰한 국물',
			'깔끔한 국물',
		],
		text: [
			'해장에 좋은',
			'얼큰한 국물',
			'깔끔한 국물',
		],
	}
]