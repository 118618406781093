import style from '../style/styles/Chat.module.css';

const Toggle = (props) => {
    const {handleTopToggle, selectedBtn} = props;
    return (
        <div className={style.Chat__toggleGroupDemo}>
            <div className={style.Chat__toggleBack}>
                <button
                    data-id="demo_ck"
                    className={style.Chat__toggle}
                    style={{ background: (selectedBtn === 'demo_ck' ? '#fff' : '') }}
                    onClick={(e) => { handleTopToggle(e) }}
                >
                    <p className='h6' style={{fontWeight: (selectedBtn === 'demo_ck' ? 700 : '')}}>쿠캣</p>
                </button>
                <button
                    data-id="demo_fd"
                    className={style.Chat__toggle}
                    style={{ background: (selectedBtn === 'demo_fd' ? '#fff' : ''), fontWeight: (selectedBtn === 'demo_fd' ? 700 : 400) }}
                    onClick={(e) => { handleTopToggle(e) }}
                >
                    <p className='h6' style={{fontWeight: (selectedBtn === 'demo_fd' ? 700 : '')}}>푸딘코</p>
                </button>
            </div>
        </div>
    )
}

export default Toggle