import { useState, useEffect, useRef } from 'react';
import compStyle from '../../style/styles/Components.module.css';
import style from '../../style/styles/Chat.module.css';
import styleMsg from '../../style/styles/Message.module.css';
import sendIcon from '../../images/send_icon.svg';
import sendIconActive from '../../images/send_icon_dlst.svg';
import CircularProgress from '@mui/material/CircularProgress';

import Message from './MessageTextBase';
import { fetchChatHistory, fetchStartingPhrase, getChatbotReply, initStartingPhrase } from '../../utils/apis';
import {CarouselFAB} from '../CarouselFAB';
import { Header, HeaderMobile } from '../Header/Header';
import { initSettingsCkat } from '../../data/initSetting';
import { useLocation } from 'react-router-dom';
import ExampleGroup from '../Chat/Example';
import { randomText } from '../../utils/utils';
import { Progress } from '../Chat/Skeleton';

const ChatFieldBaseCkat = (props) => {
    const { chatURL, resetURL, clientId, apiKey, demoId, variant } = props;
    const customSet = initSettingsCkat;

    //COMM: Change page title
    document.title = '나만의 AI 쇼핑 메이트 젠투';
    document.documentElement.style.setProperty('--color-prim-800', customSet.colorInput.color0);
    document.documentElement.style.setProperty('--color-prim-200', customSet.colorInput.color1);
    document.documentElement.style.setProperty('--color-prim-100', customSet.colorInput.color2);

    let [messages, setMessages] = useState([]);
    let [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
    let [examLabelArr, setExamLabelArr] = useState([]);
    let [examTextArr, setExamTextArr] = useState([]);
    let [input, setInput] = useState('');
    let [isMobileDevice, setIsMobileDevice] = useState(false);
    let [randomGreeting, setRandomGreeting] = useState('');
    let [resetBtn, setResetBtn] = useState(false);
    let [isInputFocused, setIsInputFocused] = useState(false);
    let [isBtnVisible, setIsBtnVisible] = useState(false);
    let [isSecondaryReply, setIsSecondaryReply] = useState(false);
    let [recentIntent, setRecentIntent] = useState('');

    const location = useLocation();
    const isVariant = location.pathname.includes('st/b');

    const email = demoId;
    const roomId = demoId;
    // const category = useParams().categoryId;
    const userId = "user " + roomId;
    const myId = roomId;
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const windowWidth = window.innerWidth || document.body.clientWidth;

    const messagesEndRef = useRef(null);
    const carouselEndRef = useRef(null);

    const onClick = async () => {
        if (isGeneratingAnswer) return;
        if (input === '') return;
        let message = [{ userId: roomId, message: input, time: new Date().toString() }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(input, roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
        setRecentIntent(chatbotReply?.intent);
        setMessages((prev) => prev.concat({userId: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent}));
        if (chatbotReply.product && chatbotReply.product.length > 0) {
            sendLoadingCompleteState();
            setIsSecondaryReply(true);
            const secondaryReply = await getChatbotReply('이 제품을 추천한 이유를 설명해줘.', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
            setRecentIntent(secondaryReply?.intent);
            setMessages((prev) => prev.concat({userId: 'Accio', message: secondaryReply.text, example: secondaryReply?.example, product: secondaryReply?.product, dialId: secondaryReply?.dialogueId, intent: secondaryReply?.intent}));
            setIsSecondaryReply(false);
        }

        window.gtag('event', 'SendMessage', {
            event_category: 'Message',
            event_label: 'Manual Input',
            source: (isVariant ? 'B' : 'A'), // AB test source
            message: input,  // message content
            init: (messages.length === 0 ? true : false), // is initiative message?
        });
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            document.getElementById('chat-input').blur();
            e.preventDefault();
            if (isGeneratingAnswer) return;
            onClick();
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        if (isGeneratingAnswer) return;
        setInput(e.currentTarget.value);
    };

    //COMM: Auto scroll to bottom when new message is present
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    const handleButtonClick_send = async (e, text) => {
        console.log('text', text);
        if (isGeneratingAnswer) return;
        if (text.length < 1) return;

        let message = [{ userId: roomId, message: text, time: new Date().toString() }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(text, roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
        setRecentIntent(chatbotReply?.intent);
        setMessages((prev) => prev.concat({ userId: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent }));
        if (chatbotReply.product && chatbotReply.product.length > 0) {
            sendLoadingCompleteState();
            setIsSecondaryReply(true);
            const secondaryReply = await getChatbotReply('이 제품을 추천한 이유를 설명해줘.', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
            setRecentIntent(secondaryReply?.intent);
            setMessages((prev) => prev.concat({userId: 'Accio', message: secondaryReply.text, example: secondaryReply?.example, product: secondaryReply?.product, dialId: secondaryReply?.dialogueId, intent: secondaryReply?.intent}));
            setIsSecondaryReply(false);
        }


        window.gtag('event', 'SendMessage', {
            event_category: 'Message',
            event_label: 'Example Message Click',
            source: (isVariant ? 'B' : 'A'), // AB test source
            message: text,  // message content
            init: (messages.length === 0 ? true : false), // is initiative message?
        });
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
    }

    const handleInputBlur = () => {
        setIsInputFocused(false);
    }

    const handleScroll = () => {
        if (carouselEndRef.current) {
            const rect = carouselEndRef.current.getBoundingClientRect();
            const chatField = document.getElementById('ChatField');
            setIsBtnVisible(rect.top < (isMobileDevice ? 16 : 100));
        }
    };

    const sendLoadingCompleteState = () => {
        const targetWindow = window.parent;
        const loadingComplateState = {
            loadingComplate: true,
        }
        targetWindow.postMessage(loadingComplateState, "*");
    }

    useEffect(() => {
        const timer = setInterval(() => {
            document.getElementById('ChatField')?.addEventListener('scroll', handleScroll());
        }, 100);
        return () => {
            clearInterval(timer);
            document.getElementById('ChatField')?.removeEventListener('scroll', handleScroll());
        }
    })

    useEffect(() => {
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages, resetBtn]);

    useEffect(() => {
        const requestURL = process.env.REACT_APP_CUST_LOAD_CKAT_URL;

        fetchChatHistory(requestURL, roomId, clientId, '', apiKey, setMessages)
            .then(res => {
                if(!res) {
                    fetchStartingPhrase(roomId, process.env.REACT_APP_CUST_PHRASE_CKAT_URL)
                        .then(phrase => handleButtonClick_send(undefined, phrase))
                        .then(initStartingPhrase(roomId, process.env.REACT_APP_CUST_PHRASE_CKAT_URL));
                }
            })
    }, [])

    useEffect(() => {
        // scroll to bottom when new msg sent
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages]);

    //COMM: Set greeting text
    useEffect(() => {
        let greeting_text1;
        let greeting_text2;
        greeting_text1 = `${userId}님 안녕하세요 😀 오늘은 어떤 상품을 찾고 계세요? 무엇이든 물어봐 주세요!`;
        greeting_text2 = `${userId}님 안녕하세요 🧐 어떤 상품을 찾아드릴까요? 젠투가 추천해드릴게요.`;

        const greeting_text = [greeting_text1, greeting_text2];
        const greeting_index = Math.floor(Math.random() * greeting_text.length);
        setRandomGreeting(greeting_text[greeting_index]);
    }, [userId]);

    useEffect(() => {
        randomText(setExamLabelArr, setExamTextArr, clientId);
    }, [clientId])

    useEffect(() => {
        if (messages[messages.length - 1]?.userId === 'Accio' && !messages[messages.length - 1].product) {
            setResetBtn(true)
        } else {
            setResetBtn(false)
        }
    }, [messages]);

    return (
        <div className={isMobileDevice ? style.Chat__chatWrap__md : style.Chat__chatWrap}>
            {
                (!isMobileDevice && windowWidth > 600) &&
                <Header 
                setMessages={setMessages} 
                setIsBtnVisible={setIsBtnVisible} 
                roomId={roomId}
                isGeneratingAnswer={isGeneratingAnswer}
                setIsGeneratingAnswer={setIsGeneratingAnswer}
                chatURL={chatURL}
                clientId={clientId}
                apiKey={apiKey}
                />
                
            }
            <div className={isMobileDevice ? style.Chat__chatTopWrap__md : style.Chat__chatTopWrap}>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width: '161px', height: '0px', position: 'absolute'}}>
                        {isMobileDevice && <div className={compStyle.Spacing__16} />}
                        {isBtnVisible && <CarouselFAB carouselEndRef={carouselEndRef} />}
                    </div>
                </div>
                {messages.length === 0 && !isMobileDevice && (
                    <div className={style.Chat__main}>
                        {windowWidth <= 600 && <div className={compStyle.Spacing__16} />}
                        <div className={style.Chat__flex}>
                            <div className={style.Util__w100mw}>
                                <div className={style.Chat__greetingWrap}>
                                    <img src={customSet.logoInput} width={32} height={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet.nameInput}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p className='h3'>
                                                {customSet.greetingInput}
                                            </p>
                                            <p className='h3'>
                                                {customSet.greetingSubInput}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                <ExampleGroup
                                    examples={examLabelArr}
                                    size={'large'}
                                    handleButtonClick_send={handleButtonClick_send}
                                    variant={variant}
                                    type='ckatShort'
                                />
                            </div>
                        </div>
                    </div>
                )}
                {messages.length === 0 && isMobileDevice && (
                    <div className={style.Chat__mainmd__onboard}>
                        <div className={compStyle.Spacing__16} />
                        <div className={style.Chat__flex}>
                            <div className={style.Util__w100mw}>
                                <div className={style.Chat__greetingWrap}>
                                    <img src={customSet.logoInput} width={32} height={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet.nameInput}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p className='h3'>
                                                {customSet.greetingInput}
                                            </p>
                                            <p className='h3'>
                                                {customSet.greetingSubInput}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                <ExampleGroup
                                    examples={examLabelArr}
                                    size={'large'}
                                    handleButtonClick_send={handleButtonClick_send}
                                    variant={variant}
                                    type='ckatShort'
                                />
                            </div>
                        </div>
                    </div>
                )}
                {messages.length !== 0 && (
                    <div style={{ width: '100%', height: '100%' }}>
                        <div
                            id="ChatField"
                            className={`${isMobileDevice ? style.Chat__mainmd : style.Chat__main} ${style.Chat__flex}`}
                        >
                            <div
                                className={style.Chat__list}
                                role="region"
                                aria-label="대화"
                                aria-live="polite"
                            >
                                {(isMobileDevice || windowWidth <= 600) && <div className={compStyle.Spacing__16} />}
                                <div className={isMobileDevice ? style.Chat__greetingWrap__md : style.Chat__greetingWrap}>
                                    <img src={customSet.logoInput} width={32} height={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet.nameInput}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p className='h3'>
                                                {customSet.greetingInput}
                                            </p>
                                            <p className='h3'>
                                                {customSet.greetingSubInput}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                {messages?.map(({ userId, message, example, product, dialId, intent }, index) => {
                                    console.log('msg', message);
                                    return (
                                        <Message
                                            key={`${userId}_${index}`}
                                            userId={userId}
                                            text={message}
                                            myId={myId}
                                            clientId={clientId}
                                            example={index === messages.length - 1 && example}
                                            product={product}
                                            dialId={dialId}
                                            lastIdx={index === (messages.length - 1)}
                                            handleButtonClick_send={handleButtonClick_send}
                                            isMobileDevice={isMobileDevice}
                                            carouselEndRef={carouselEndRef}
                                            intent={intent}
                                            messagesEndRef={messagesEndRef}
                                            customSet={customSet}
                                            isIOS={isIOS}
                                            roomId={roomId}
                                            setMessages={setMessages}
                                            setResetBtn={setResetBtn}
                                        >
                                            {' '}
                                        </Message>
                                    );
                                })}
                                {
                                    isGeneratingAnswer &&
                                    <div>
                                        <li className={isMobileDevice ? styleMsg.Message__list__md : styleMsg.Message__list} style={{ width: 'fit-content' }}>
                                            <img src={customSet.logoInput} width={32} height={32} className={style.Chat__icon} />
                                            <div>
                                                <p className='h9' style={{color: '#999999'}}>{customSet.nameInput}</p>
                                                <div className={compStyle.Spacing__4} />
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <div className={styleMsg.Message__content}>
                                                        <div className={styleMsg.Message__text}>
                                                        <Progress isMobileDevice={isMobileDevice} progressState={isSecondaryReply ? 'second' : 'first'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <div className={compStyle.Spacing__8} />
                                        {/* {
                                            messages.length < 2 &&
                                            <>
                                                <SkeletonCarousel isMobileDevice={isMobileDevice} />
                                                <div className={compStyle.Spacing__16}/>
                                            </>
                                        } */}
                                    </div>
                                }
                                <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={isMobileDevice ? style.Chat__bottommd : style.Chat__bottom}>
                <div className={isMobileDevice ? style.Chat__inputmd : style.Chat__input}>
                    {
                        (isMobileDevice || windowWidth <= 600) &&
                        <HeaderMobile
                            setMessages={setMessages} 
                            setIsBtnVisible={setIsBtnVisible} 
                            roomId={roomId}
                            isGeneratingAnswer={isGeneratingAnswer}
                            setIsGeneratingAnswer={setIsGeneratingAnswer}
                            chatURL={chatURL}
                            clientId={clientId}
                            apiKey={apiKey}
                        />
                    }
                    <div className={
                        isMobileDevice ?
                        (!isGeneratingAnswer ? style.Chat__input1md : style.Chat__input1md__block) :
                        (!isGeneratingAnswer ? style.Chat__input1 : style.Chat__input1__block)
                    } >
                        <input
                            id='chat-input'
                            className={style.Chat__inputfield1}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={onChange}
                            onKeyUp={handleKeyPress}
                            type='text'
                            placeholder={!isGeneratingAnswer ? `${customSet.nameInput}에게 물어보세요` : '답변 생성 중에는 입력하실 수 없어요'}
                            value={input}
                            aria-level='메시지'
                        />
                        <div className={style.Chat__inputButtonWrap}>
                            {
                                isGeneratingAnswer ?
                                    <CircularProgress
                                        id="LOADING"
                                        size={20}
                                        className={style.Chat__loading}
                                        aria-label="로딩 중"
                                        role="alert"
                                    /> :
                                    <button
                                        className={style.Chat__inputButton}
                                        onClick={onClick}
                                        aria-level='전송하기'
                                    >
                                        <img src={isInputFocused ? sendIconActive : sendIcon} width={24}/>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
                <div className={isMobileDevice ? style.Chat__footer__md : style.Chat__footer} ><a href='https://www.gentooai.com'>powered by GENTOO</a></div>
            </div>
        </div>
    );
};

export default ChatFieldBaseCkat