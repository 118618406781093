
const tutorialContents = [
    {
        title: '다운로드',
        desc: '엑셀 양식을 다운로드 하세요.',
        warn: '',
    },
    {
        title: '정보 입력',
        desc: '다운로드한 엑셀 파일을 열고, 항목에 맞춰서 정보를 기재해주세요 (상품 개수는 10~30개)',
        warn: '*주의 : 고객의 개인정보는 포함하지 마세요',
    },
    {
        title: '파일 저장',
        desc: '정보 입력이 끝나면 파일을 다른 이름으로 저장해주세요',
        warn: '',
    },
    {
        title: '파일 업로드',
        desc: '파일 업로드 후, ‘데모 생성하기’를 누르면 끝!',
        warn: '',
    },
]

export default tutorialContents;