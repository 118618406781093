import { chatDataBala, chatDataCkat, chatDataCkatShort, chatDataDlst, chatDataDrink, chatDataFdko, chatDataNose, chatDataPick, testDataFdko } from "../data/chatData";

sessionStorage.setItem('token', 'login');

export const randomText = async (setExamLabelArr, setExamTextArr, category) => {
	if (setExamLabelArr === undefined || setExamTextArr === undefined) return;
	let categoryData;
	switch (category) {
		case 'demo_ck':
			categoryData = chatDataCkat;
			break;
		case 'demo_fd':
			categoryData = chatDataFdko;
			break;
		case 'ckat':
			categoryData = chatDataCkat;
			break;
		case 'fdko_wine':
			categoryData = chatDataFdko;
			break;
		case 'alle':
			categoryData = chatDataDrink;
			break;
		case 'dlst':
			categoryData = chatDataDlst;
			break;
		case 'bala':
			categoryData = chatDataBala;
			break;
		case 'nose':
			categoryData = chatDataNose;
			break;
		case 'viml':
			categoryData = chatDataBala;
			break;
		case 'pick':
			categoryData = chatDataPick;
			break;
		case 'test':
			categoryData = testDataFdko;
			break;
		case 'ckatShort':
			categoryData = chatDataCkatShort;
			break;
		default:
			categoryData = chatDataDrink;
	}
	const randomIdx = Math.floor(Math.random() * categoryData[0].label.length);
	let dataArr = { labelArr: [], textArr: [] };

	categoryData.forEach((data) => {
		dataArr.labelArr.push(data.label[randomIdx]);
		dataArr.textArr.push(data.text[randomIdx]);
	})

	setExamLabelArr(dataArr.labelArr);
	setExamTextArr(dataArr.textArr);
}

export const addComma = (num) => {
	if (num === undefined) return;
	const parts = isNaN(num) ? num : num.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}

export const getServiceType = (type) => {
	switch (type) {
		case '파트너':
			return '/img/units/carousel-tag-partner.png';
			break;
		case '스토어':
			return '/img/units/carousel-tag-store.png';
			break;
		case '택배배송':
			return '/img/units/carousel-tag-delivery.png';
			break;
		case '편픽':
			return '/img/units/carousel-tag-cu.png';
			break;
		case '신라면세점':
			return '/img/units/carousel-tag-silla.png';
			break;
		default:
			return '';
	}
}

export const getCustomProperty = (clientId) => {
	const apiUrls = JSON.parse(process.env.REACT_APP_API_URLS);
	const apiKey = JSON.parse(process.env.REACT_APP_API_KEYS);

	return {
		clientId: clientId,
		apiUrls: apiUrls[clientId],
		apiKey: apiKey[clientId],
	}
}

export const getKeywordsList = (topKeywords) => {
	let keywordsArr = [];
	let productsObj = {};
	
	topKeywords.map((data, idx) => {
		keywordsArr.push(data.keyword);
		productsObj[data.keyword] = data.top_items
	})
	return {keywordsArr, productsObj}
}

export const replaceAmpersand = (obj) => {
    // 객체의 각 키에 대해 순회
    for (let key in obj) {
        if (typeof obj[key] === 'string') {
            // 값이 문자열인 경우 &를 @@으로 치환
            obj[key] = obj[key].replace('@@', '&');
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // 값이 객체나 배열인 경우 재귀적으로 함수 호출
            replaceAmpersand(obj[key]);
        }
    }
}