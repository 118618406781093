import { useParams } from "react-router-dom";
import { initSettingsCkat } from "../../data/initSetting";
import ChatFieldBaseCkat from "../Common/ChatFieldBaseCkat";


//COMM: Main Component that contains messages, textfield, etc.
const ChatCookat = (props) => {
    const {variant} = props;
    const demoId = useParams().chatUserId;
    localStorage.setItem('customSet', JSON.stringify(initSettingsCkat));

    return (
        <ChatFieldBaseCkat
            chatURL={process.env.REACT_APP_CUST_CHAT_CKAT_URL}
            resetURL={process.env.REACT_APP_CUST_CHAT_RENEW_CKAT_URL}
            apiKey={process.env.REACT_APP_X_API_KEY_CKAT}
            clientId="ckat"
            variant={variant}
            demoId={demoId}
        />
    );
};

export default ChatCookat;