import tutorial1 from '../images/custom/tutorial1.gif';
import tutorial2 from '../images/custom/tutorial2.gif';
import tutorial3 from '../images/custom/tutorial3.gif';
import tutorial4 from '../images/custom/tutorial4.gif';

const tutorialImages = {
    tutorial1,
    tutorial2,
    tutorial3,
    tutorial4,
}

export default tutorialImages