import React, { useEffect, useState } from 'react';
import compStyle from '../../../style/styles/Components.module.css';
import style from '../../../style/styles/Message.module.css';
import { CarouselDemo, CarouselDlst } from '../../Custom/Carousel.jsx';
import gentooSymbol from '../../../images/gentoo_symbol.png';

const Message = (props) => {
	const {
		userId,
		text,
		myId,
		clientId,
		example = undefined,
		product,
		dialId,
		lastIdx = false,
		handleButtonClick_send,
		isMobileDevice,
		carouselEndRef,
		intent,
		messagesEndRef,
		isBlur } = props;
	const isMyMessage = userId === myId;
	const [shouldFollowup, setShouldFollowup] = useState(false);
	const [showExam, setShowExam] = useState(false);

	let borderStyle = (
		isMobileDevice ?
			(isMyMessage ? style.Message__listmy__md : style.Message__list__md) :
			(isMyMessage ? style.Message__listmy : style.Message__list)
	);
	let blurStyle = isBlur ? style.Message__blur : null;

	const carouselComp = {
		demo_ck: CarouselDemo,
		ckat: CarouselDemo,
		demo_fd: CarouselDemo,
		dlst: CarouselDlst,
	};

	let Carousel = carouselComp[clientId];

	const scrollToBottom = () => {
		messagesEndRef?.current?.scrollIntoView({ behavior: 'auto' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [shouldFollowup, showExam])

	useEffect(() => {
		if (!lastIdx) {
			setShouldFollowup(false);
			return
		} else if (intent === '제공상세' || intent === '정보') {
			const timeoutFollowup = setTimeout(() => {
				setShouldFollowup(true);
			}, 7000);

			const timeoutExam = setTimeout(() => {
				setShowExam(true);
			}, 8000);

			return () => {
				clearTimeout(timeoutFollowup);
				clearTimeout(timeoutExam);
			}
		} else if (intent === '일반') {
			setShowExam(true);
		}
	}, [intent])

	// user chat message return
	if (product && product.length !== 0) {
		return (
			<>
				{
					isMyMessage ?
						<li className={`${borderStyle} ${blurStyle}`} >
							<div className={style.Message__content__my}>
								<div className={style.Message__text}>
									<p className='h3'>{text}</p>
								</div>
							</div>
						</li> :
						<>
							<li className={`${borderStyle} ${blurStyle}`}>
								<img src={gentooSymbol} width={32} className={style.Message__icon} />
								<div>
									<p className='h9' style={{ color: '#999999' }}>젠투</p>
									<div className={compStyle.Spacing__4} />
									<div className={style.Message__content}>
										<div className={style.Message__text}>
											<p className='h3'>{text[0]}</p>
										</div>
									</div>
								</div>
							</li>
							<div className={`${compStyle.Spacing__8} ${blurStyle}`} />
							{
								product.length !== 0 &&
								<div className={blurStyle}>
									<Carousel product={product} dialId={dialId} isMobileDevice={isMobileDevice} carouselEndRef={carouselEndRef} />
								</div>
							}
							{
								(showExam && example?.length > 0) &&
								<div className={style.Message__examBtnWrap}>
									{
										example?.map((data, idx) => {
											return (
												<button
													className={isMobileDevice ? compStyle.Button__examStatement__md : compStyle.Button__examStatement}
													key={idx}
													data-value={data}
													onClick={(e) => {
														handleButtonClick_send(data)
													}}
												>
													<p className={isMobileDevice ? 'h6' : 'h3'}>{data}</p>
												</button>
											)
										})
									}
								</div>
							}
						</>
				}
			</>
		)
	} else {
		return (
			<>
				{
					isMyMessage ?
						<>
							<li className={`${borderStyle} ${blurStyle}`}>
								<div className={style.Message__content__my}>
									<div className={style.Message__text}>
										<p className='h3'>{text}</p>
									</div>
								</div>
							</li>
							<div className={compStyle.Spacing__16} />
						</> :
						<>
							<div className={`${borderStyle} ${blurStyle}`}>
								<img src={gentooSymbol} width={32} className={style.Message__icon} />
								<div>
									{!isMyMessage && <p className='h9' style={{ color: '#999999' }}>젠투</p>}
									<div className={compStyle.Spacing__4} />
									<div className={isMyMessage ? style.Message__content__my : style.Message__content}>
										<div className={style.Message__text}>
											{
												intent === '제공상세' && text.length > 0 ?
													text.map((list, idx) => { return <li key={idx} className='h3' style={{ marginLeft: '20px', textIndent: '-20px' }}>{list}</li> }) :
													<p className='h3'>{text}</p>
											}
										</div>
									</div>
									{
										(shouldFollowup && lastIdx) &&
										<>
											<div className={compStyle.Spacing__4} />
											<div className={style.Message__content} style={{ width: 'fit-content' }}>
												<div className={style.Message__text}>
													{
														intent === '제공상세' &&
														<p className='h3'>더 자세한 추천이 필요하신가요? 말씀주시면 다른 것도 찾아볼게요.</p>
													}
													{
														(intent === '정보' || intent === '일반') &&
														<p className='h3'>더 궁금하신 내용은 없으신가요?</p>
													}
												</div>
											</div>
											<div ref={messagesEndRef} style={{ minHeight: '1px' }} />
										</>
									}
								</div>
							</div>
							<div className={compStyle.Spacing__16} />
							{
								(showExam && example?.length > 0) &&
								<div className={style.Message__examBtnWrap} >
									{
										example?.map((data, idx) => {
											return (
												<div key={idx}>
													<button
														className={isMobileDevice ? compStyle.Button__examStatement__md : compStyle.Button__examStatement}
														data-value={data}
														onClick={(e) => {
															handleButtonClick_send(data)
														}}
													>
														<p className={isMobileDevice ? 'h6' : 'h3'}>{data}</p>
													</button >
												</div>
											)
										})
									}
									<div ref={messagesEndRef} style={{ minHeight: '1px' }} />
								</div>
							}
						</>

				}
				<div ref={messagesEndRef} style={{ minHeight: '1px' }} />
			</>
		)
	}
}

export default Message;