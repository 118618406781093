import { useState } from 'react';
import compStyle from '../../style/styles/Components.module.css';
import { requestSignup } from '../../utils/apis';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [company, setCompany] = useState('');
    const [job, setJob] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === '') alert('이메일을 입력해주세요!')
        else if (password === '') alert('비밀번호를 입력해주세요!')
        else if (company === '') alert('회사명을 입력해주세요!')
        else if (job === '') alert('직무를 입력해주세요!')
        else {
            const response = await requestSignup(email, password, company, job);
            if (response) navigate('/test')
            else {alert('생성 과정에서 문제가 발생했습니다. 다시 시도해주세요.')}
        }
    }

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{width: "100%", maxWidth: "440px"}}>
                <div className={compStyle.Spacing__56} />
                <p className="h1" style={{fontSize: '24px'}}>젠투에서 사용할 계정을 만들어주세요.</p>
                <form onSubmit={handleSubmit}>
                    <div className={compStyle.Spacing__40} />
                    <div>
                        <label htmlFor="id" style={{width: "100%", color: "#666", fontWeight: '700'}}>이메일 </label>
                        <div className={compStyle.Spacing__8} />
                        <input 
                            id="id" 
                            name="id" 
                            type="email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{width: "100%", padding: "8px 16px", border: '1px solid #eee', borderRadius: '10px', background: '#fafafa'}}/>
                    </div>
                    <div className={compStyle.Spacing__24} />
                    <div>
                        <label htmlFor="password" style={{width: "100%", color: "#666", fontWeight: '700'}}>비밀번호 </label>
                        <div className={compStyle.Spacing__8} />
                        <input 
                            id="password" 
                            name="password" 
                            type="password" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{width: "100%", padding: "8px 16px", border: '1px solid #eee', borderRadius: '10px', background: '#fafafa'}}/>
                    </div>
                    <div className={compStyle.Spacing__24} />
                    <div>
                        <label htmlFor="company" style={{width: "100%", color: "#666", fontWeight: '700'}}>회사명 </label>
                        <div className={compStyle.Spacing__8} />
                        <input 
                            id="company" 
                            name="company" 
                            type="company" 
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            style={{width: "100%", padding: "8px 16px", border: '1px solid #eee', borderRadius: '10px', background: '#fafafa'}}/>
                    </div>
                    <div className={compStyle.Spacing__24} />
                    <div>
                        <label htmlFor="job" style={{width: "100%", color: "#666", fontWeight: '700'}}>직무 </label>
                        <div className={compStyle.Spacing__8} />
                        <input 
                            id="job" 
                            name="job" 
                            type="job" 
                            value={job}
                            onChange={(e) => setJob(e.target.value)}
                            style={{width: "100%", padding: "8px 16px", border: '1px solid #eee', borderRadius: '10px', background: '#fafafa'}}/>
                    </div>
                    <div className={compStyle.Spacing__40} />
                    <button 
                        type='submit'
                        style={{width: '100%', border: 'none', padding: '16px', borderRadius: '16px', background: '#154ccb', color: '#fff', fontSize: '16px', fontWeight: '700'}}
                    >
                        회원 가입
                    </button>
                </form>
                <div className={compStyle.Spacing__40} />
                <p style={{width: '100%', textAlign: 'center'}}>이미 계정이 있으신가요? <a href='/login' style={{color: '#154ccb', fontWeight: '700'}}>로그인</a></p>
            </div>
        </div>
    )
}

export default Signup;