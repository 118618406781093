import { useState } from 'react';
import testStyle from '../../style/styles/Test.module.css';
import compStyle from '../../style/styles/Components.module.css';
import leftBubble from '../../images/custom/left-bubble.png';
import rightBubble from '../../images/custom/right-bubble.png';
import gentooLogo from '../../images/custom/gentoo-logo.png';
import mainPhone from '../../images/custom/main-phone.png';
import icnSend from '../../images/custom/send_icon.svg';
import tutorialImages from '../../data/customImages';
import tutorialContents from '../../data/tutorialContents';
import bang from '../../images/custom/bang.png';
import modalPostbox from '../../images/custom/modal-postbox.png';
import { requestSignup } from '../../utils/apis';


const Register = () => {
    const [selectedTutorial, setSelectedTutorial] = useState('tutorial1');
    const [isRegister, setIsRegister] = useState(false);
    const [email, setEmail] = useState('');

    const handleClickRegister = async () => {
        const regex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const match = email.match(regex);
        console.log('register email', email);

        if (email === '') alert('이메일을 입력해주세요!');
        else if (match === null) alert('올바른 이메일 형식을 입력해주세요.')
        else {
            const response = await requestSignup(email);
            // if (response) setIsRegister(true);
            if (response) {
                setIsRegister(false);
                window.location.href = `${process.env.REACT_APP_CONSOLE_URL}/agent/create/${encodeURIComponent(email)}`;
            } //TODO: 생성페이지(in console) 배포 시 redirect code로 변경
            else {alert('등록 과정에서 문제가 발생했습니다. 다시 시도해주세요.')};
        }
    }

    return (
        <>
            {/* <FloatingButton /> */}
            <div 
                id='gentoo-ref-scroll'
                className={testStyle.Custom__Container} 
                style={{ overflowY: 'scroll' }}
            >
                <div className={testStyle.Custom__MainBanner}>
                    <div className={testStyle.Custom__MainBanner__Wrap}>
                        <div>
                            <img src={gentooLogo} className={testStyle.Custom__MainBanner__Logo} />
                            <div className={compStyle.Spacing__16} />
                            <p className={`${testStyle.Custom__MainBanner__Title} ${testStyle.fwL}`}>고객과 대화하며 고객을 이해하는</p>
                            <p className={`${testStyle.Custom__MainBanner__Title} ${testStyle.fwB}`}>AI 점원 ‘젠투’</p>
                        </div>
                        <div className={testStyle.Custom__MainBanner__ContentBox}>
                            <div className={testStyle.Custom__MainBanner__ContentBox__bottom}>
                                    <img src={mainPhone} style={{ width: '100%', verticalAlign: 'bottom' }} />
                            </div>
                            <div className={testStyle.Custom__MainBanner__ContentBox__top}>
                                <div style={{ width: 'fit-content' }}>
                                    <p className={`${testStyle.Custom__MainBanner__Content1} ${testStyle.fwB}`}>다른 쇼핑몰에 적용된 젠투 데모를 체험할 수 있어요.</p>
                                    <p className={`${testStyle.Custom__MainBanner__Content2} ${testStyle.fwB}`}>젠투와 직접 대화해 보세요.</p>
                                    <a href='/' target='_blank' style={{ width: '100%' }}>
                                        <button className={testStyle.Custom__MainBanner__Button}>
                                            <p className={testStyle.fwB}>예시 데모 사용해보기</p>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className={testStyle.Custom__MainBanner__Bubbles__left} src={leftBubble} />
                    <img className={testStyle.Custom__MainBanner__Bubbles__right} src={rightBubble} />
                </div>
                <div className={testStyle.Custom__SubBanner}>
                    <div className={testStyle.Custom__SubBanner__Wrap}>
                        <p className={`${testStyle.Custom__SubBanner__SubTitle} ${testStyle.fwB}`}>우리 쇼핑몰 맞춤형 데모를 만들어보고 싶다면?</p>
                        <p className={`${testStyle.Custom__SubBanner__Title} ${testStyle.fwB}`}>업로드한 데이터로 대화하는 젠투를 5분만에 만나보세요.</p>
                        <div className={testStyle.Custom__SubBanner__InputWrap}>
                            <input
                                type='email'
                                placeholder='업무 이메일을 입력해주세요'
                                className={`${testStyle.Custom__SubBanner__Input} ${testStyle.fwR}`}
                                value={email}
                                onChange={(e) => {setEmail(e.target.value)}}
                            />
                            <button
                                className={`${testStyle.Custom__SubBanner__Button} ${testStyle.fwB}`}
                                onClick={() => handleClickRegister()}
                            >
                                <img src={icnSend} />
                                <span>무료로 맞춤 데모 만들기</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={testStyle.Custom__Tutorials}>
                    <div className={testStyle.Custom__Tutorials__Wrap}>
                        <div>
                            <img src={bang} className={testStyle.Custom__Tutorials__TitleIcon} />
                            <span className={`${testStyle.Custom__Tutorials__Title} ${testStyle.fwB}`}> 아래와 같이 간단하게 데모를 만들 수 있어요</span>
                        </div>
                        <div className={testStyle.Custom__Tutorials__GridBox}>
                            <div className={testStyle.Custom__Tutorials__GridBox__top}>
                                <img src={tutorialImages[selectedTutorial]} className={testStyle.Custom__Tutorials__TutorialGIF} />
                            </div>
                            <div className={testStyle.Custom__Tutorials__GridBox__bottom}>
                                {
                                    tutorialContents.map((content, idx) => {
                                        return (
                                            <>
                                                <input
                                                    type='radio' id={`tutorial${idx + 1}`} name='tutorial' value={`tutorial${idx + 1}`}
                                                    className={testStyle.Custom__Tutorials__Input}
                                                    defaultChecked={selectedTutorial === `tutorial${idx + 1}`}
                                                    onClick={(e) => { setSelectedTutorial(e.currentTarget.id) }}
                                                />
                                                <label htmlFor={`tutorial${idx + 1}`} className={selectedTutorial === `tutorial${idx + 1}` ? testStyle.Custom__Tutorials__SelectedLabel : testStyle.Custom__Tutorials__Label} >
                                                    <div style={{ display: 'flex' }}>
                                                        <p className={`${testStyle.Custom__Tutorials__Label__Title__idx} ${testStyle.fwB}`}>{idx + 1}</p>
                                                        <div style={{ textAlign: 'left' }}>
                                                            <p className={`${testStyle.Custom__Tutorials__Label__Title} ${testStyle.fwB}`} >{content.title}</p>
                                                            {
                                                                selectedTutorial === `tutorial${idx + 1}` &&
                                                                <>
                                                                    <div className={compStyle.Spacing__8} />
                                                                    <p className={testStyle.customH14} style={{ color: '#666' }} >{content.desc}</p>
                                                                    <p className={testStyle.customH14} style={{ color: '#ff5555' }}>{content.warn}</p>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </label>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isRegister &&
                <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: '100', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{width: '420px', height: '283px', background: '#fff', borderRadius: '20px', padding: '20px 24px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <img src={modalPostbox} width={50} />
                        <div className={compStyle.Spacing__24} />
                        <p className={testStyle.customH9} style={{maxWidth: '313px', width: '100%', textAlign: 'center'}}>지금 이메일로 '젠투' 데모를 만들 수 있는 링크를 보내드렸어요!</p>
                        <div className={compStyle.Spacing__16} />
                        <button 
                            onClick={() => setIsRegister(false)}
                            style={{width: '100%', padding: '12px', textAlign: 'center', background: '#154ccb', borderRadius: '10px', border: 'none'}}
                        >
                            <p className={testStyle.customH11} style={{color: '#fff'}}>확인</p>
                        </button>
                        <div className={compStyle.Spacing__12} />
                        <p className={testStyle.customH14} style={{color: '#999'}}>혹시 5분 안에 메일이 도착하지 않는다면 waddle@waddlelab.com으로 연락해주세요.</p>
                    </div>
                </div>
            }
        </>
    )
}

export default Register