import { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import compStyle from '../../style/styles/Components.module.css';
import style from '../../style/styles/Chat.module.css';
import styleMsg from '../../style/styles/Message.module.css';
// import sendIcon from '../../images/send_icon.svg';
import sendIcon from '../../images/icn_send_24.png';
import sendIconActive from '../../images/send_icon_active.svg';
import sendIconDlst from '../../images/send_icon_dlst.svg';
import CircularProgress from '@mui/material/CircularProgress';
import gentooSymbol from '../../images/gentoo_symbol.png';

import Message from './MessageTextBase';
import { randomText } from '../../utils/utils';
import { fetchChatHistory, getChatbotReply, resetQueryCondition } from '../../utils/apis';
import { CarouselFAB, ShortcutBanner } from '../CarouselFAB';
import { Header, HeaderMobile } from '../Header/Header';
import ExampleGroup from '../Chat/Example';
import { Progress, ProgressDLST } from '../Chat/Skeleton';
import { gtagSendMessage } from '../../utils/gtag';

const ChatFieldBase = (props) => {
    let { chatURL, resetURL, loadURL = '', clientId, apiKey, demoId = undefined, variant, customSet = false } = props;

    //COMM: Change page title
    document.title = '나만의 AI 쇼핑 메이트 젠투';
    if (customSet) {
        document.documentElement.style.setProperty('--color-prim-800', customSet.colorInput.color0);
        document.documentElement.style.setProperty('--color-prim-200', customSet.colorInput.color1);
        document.documentElement.style.setProperty('--color-prim-100', customSet.colorInput.color2);
    }
    const activeSendIcon = clientId === 'dlst' ? sendIconDlst : sendIconActive;

    let [messages, setMessages] = useState([]);
    let [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
    let [examLabelArr, setExamLabelArr] = useState([]);
    let [examTextArr, setExamTextArr] = useState([]);
    let [input, setInput] = useState('');
    let [isMobileDevice, setIsMobileDevice] = useState(false);
    let [randomGreeting, setRandomGreeting] = useState('');
    let [resetBtn, setResetBtn] = useState(false);
    let [isInputFocused, setIsInputFocused] = useState(false);
    let [isBtnVisible, setIsBtnVisible] = useState(false);
    let [isSecondaryReply, setIsSecondaryReply] = useState(false);
    let [productIdx, setProductIdx] = useState(0);

    const location = useLocation();
    const isVariant = location.pathname.includes('st/b');

    const demoIdParam = useParams().demoId;
    const roomId = demoId || demoIdParam;
    // const category = useParams().categoryId;
    const userId = "user" + roomId;
    const myId = roomId;
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const messagesEndRef = useRef(null);
    const carouselEndRef = useRef(null);

    const onClick = async () => {
        if (isGeneratingAnswer) return;
        if (input === '') return;
        const itemId = sessionStorage.getItem('itemId');
        const userCount = messages.filter(item => item.userId !== 'Accio').length;
        let message = [{id: userCount, userId: roomId, message: input, time: new Date().toString() }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(input, roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey, itemId);
        setMessages((prev) => prev.concat({id: userCount+1, userId: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent, userGroup: chatbotReply?.userGroup}));
        if (clientId !== 'dlst' && chatbotReply.product && chatbotReply.product.length > 0) {
            setIsSecondaryReply(true);
            const secondaryReply = await getChatbotReply('이 제품을 추천한 이유를 설명해줘.', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
            setMessages((prev) => prev.concat({id: userCount+1, userId: 'Accio', message: secondaryReply.text, example: secondaryReply?.example, product: secondaryReply?.product, dialId: secondaryReply?.dialogueId, intent: secondaryReply?.intent}));
            setIsSecondaryReply(false);
        }

        gtagSendMessage(userCount, "Manual Input", input, clientId)
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isGeneratingAnswer) return;
            onClick();
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        setInput(e.currentTarget.value);
    };

    //COMM: Auto scroll to bottom when new message is present
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    const handleButtonClick_send = async (e, text, itemId = 'general') => {
        e.stopPropagation();
        if (isGeneratingAnswer) return;
        const userCount = messages.filter(item => item.userId !== 'Accio').length;
        let message = [{id: userCount, userId: roomId, message: text, time: new Date().toString() }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(text, roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey, itemId);
        setMessages((prev) => prev.concat({id: userCount+1, userId: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent, userGroup: chatbotReply?.userGroup }));
        if (clientId !== 'dlst' && chatbotReply.product && chatbotReply.product.length > 0) {
            setIsSecondaryReply(true);
            const secondaryReply = await getChatbotReply('이 제품을 추천한 이유를 설명해줘.', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
            setMessages((prev) => prev.concat({id: userCount+1, userId: 'Accio', message: secondaryReply.text, example: secondaryReply?.example, product: secondaryReply?.product, dialId: secondaryReply?.dialogueId, intent: secondaryReply?.intent}));
            setIsSecondaryReply(false);
        }

        gtagSendMessage(userCount, "Example Message Click", text, clientId);
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
    }

    const handleInputBlur = () => {
        setIsInputFocused(false);
    }

    const handleScroll = () => {
        if (carouselEndRef.current) {
            const rect = carouselEndRef.current.getBoundingClientRect();
            setIsBtnVisible(rect.top < (isMobileDevice ? 16 : 100));
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            document.getElementById('ChatField')?.addEventListener('scroll', handleScroll());
        }, 100);
        return () => {
            clearInterval(timer);
            document.getElementById('ChatField')?.removeEventListener('scroll', handleScroll());
        }
    })

    useEffect(() => {
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages, resetBtn]);

    useEffect(() => {
        //COMM: Check whether the device is mobile or not
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobileDevice(isMobile);

        //COMM: Move VoiceOver focus to HEADER
        setTimeout(() => {
            const header = document.getElementById('HEADER');
            if (header) {
                header.tabIndex = -1;
                header.focus();
            }
        }, 250);
    }, []);

    // API request the reset of chat history at server
    useEffect(() => {
        if (clientId !== 'viml') resetQueryCondition(roomId, resetURL, clientId, apiKey);
    }, [roomId])

    useEffect(() => {
        // scroll to bottom when new msg sent
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages]);

    //COMM: Set greeting text
    useEffect(() => {
        let greeting_text1;
        let greeting_text2;
        greeting_text1 = `안녕하세요 😀 오늘은 어떤 상품을 찾고 계세요? 무엇이든 물어봐 주세요!`;
        greeting_text2 = `안녕하세요 🧐 어떤 상품을 찾아드릴까요? 젠투가 추천해드릴게요.`;

        const greeting_text = [greeting_text1, greeting_text2];
        const greeting_index = Math.floor(Math.random() * greeting_text.length);
        setRandomGreeting(greeting_text[greeting_index]);
    }, [userId]);

    // Top-toggle switching style
    useEffect(() => {
        randomText(setExamLabelArr, setExamTextArr, clientId);
    }, [clientId])

    useEffect(() => {
        if (messages[messages.length - 1]?.userId === 'Accio' && !messages[messages.length - 1].product) {
            setResetBtn(true)
        } else {
            setResetBtn(false)
        }
    }, [messages]);

    useEffect(() => {
        if (clientId === 'viml') fetchChatHistory(loadURL, roomId, clientId, '', apiKey, setMessages)
    }, [])

    return (
        <div 
            className={isMobileDevice ? style.Chat__chatWrap__md : style.Chat__chatWrap}
        >
            {
                !isMobileDevice &&
                <Header 
                    setMessages={setMessages} 
                    setIsBtnVisible={setIsBtnVisible} 
                    roomId={roomId}
                    isGeneratingAnswer={isGeneratingAnswer}
                    setIsGeneratingAnswer={setIsGeneratingAnswer}
                    chatURL={chatURL}
                    clientId={clientId}
                    apiKey={apiKey}
                />
            }
            <div className={isMobileDevice ? style.Chat__chatTopWrap__md : style.Chat__chatTopWrap}>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width: (clientId === 'dlst' ? '100%' : '161px'), height: '0px', position: 'absolute'}}>
                        {(isMobileDevice && clientId !== 'dlst') && <div className={compStyle.Spacing__16} />}
                        {
                            (isBtnVisible && clientId === 'dlst') &&
                            <ShortcutBanner 
                                carouselEndRef={carouselEndRef} 
                                productIdx={productIdx}
                                product={messages.filter(message => message.intent === '제공')}
                                userId={userId}
                                clientId={clientId}
                            />
                        }
                        {
                            (isBtnVisible && clientId !== 'dlst') &&
                            <CarouselFAB 
                                carouselEndRef={carouselEndRef} 
                                productIdx={productIdx}
                                product={messages.filter(message => message.intent === '제공')}
                                userId={userId}
                                clientId={clientId}
                            />
                        }
                    </div>
                </div>
                {messages.length === 0 && !isMobileDevice && (
                    <div className={style.Chat__main}>
                        <div className={style.Chat__flex}>
                            <div className={style.Util__w100mw}>
                                <div className={style.Chat__greetingWrap}>
                                <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p className='h3'>
                                                {customSet ? customSet.greetingInput : randomGreeting}
                                            </p>
                                            {
                                                (clientId !== 'dlst' && customSet) && 
                                                <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                <ExampleGroup
                                    clientId={clientId}
                                    examples={examLabelArr}
                                    size={'large'}
                                    handleButtonClick_send={handleButtonClick_send}
                                    variant={clientId === 'dlst' ? 'dlst' : variant}
                                    // variant={variant}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {messages.length === 0 && isMobileDevice && (
                    <div className={style.Chat__mainmd__onboard}>
                        <div className={compStyle.Spacing__16} />
                        <div className={style.Chat__flex}>
                            <div className={style.Util__w100mw}>
                                <div className={style.Chat__greetingWrap}>
                                    <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p className='h3'>
                                                {customSet ? customSet.greetingInput : randomGreeting}
                                            </p>
                                            {
                                                (clientId !== 'dlst' && customSet) &&
                                                <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                <ExampleGroup
                                    clientId={clientId}
                                    examples={examLabelArr}
                                    size={'large'}
                                    handleButtonClick_send={handleButtonClick_send}
                                    variant={clientId === 'dlst' ? 'dlst' : variant}
                                    // variant={variant}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {messages.length !== 0 && (
                    <div style={{ width: '100%', height: '100%' }}>
                        <div
                            id="ChatField"
                            className={`${isMobileDevice ? style.Chat__mainmd : style.Chat__main} ${style.Chat__flex}`}
                        >
                            <div
                                className={style.Chat__list}
                                role="region"
                                aria-label="대화"
                                aria-live="polite"
                            >
                                {isMobileDevice && <div className={compStyle.Spacing__16} />}
                                <div className={isMobileDevice ? style.Chat__greetingWrap__md : style.Chat__greetingWrap}>
                                    <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p className='h3'>
                                            {customSet ? customSet.greetingInput : randomGreeting}
                                            </p>
                                            {
                                                (clientId !== 'dlst' && customSet) &&
                                                <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                {messages?.map(({ id, userId, message, example, product, dialId, intent, time, userGroup }, index) => {
                                    return (
                                        <Message
                                            key={`${userId}_${index}`}
                                            id={id}
                                            userId={userId}
                                            myId={myId}
                                            clientId={clientId}
                                            text={message}
                                            example={index === messages.length - 1 && example}
                                            product={product}
                                            dialId={dialId}
                                            lastIdx={index === (messages.length - 1)}
                                            handleButtonClick_send={handleButtonClick_send}
                                            isMobileDevice={isMobileDevice}
                                            carouselEndRef={carouselEndRef}
                                            intent={intent}
                                            messagesEndRef={messagesEndRef}
                                            time={time}
                                            customSet={customSet}
                                            userGroup={userGroup}
                                            productIdx={productIdx}
                                            setProductIdx={setProductIdx}
                                        >
                                            {' '}
                                        </Message>
                                    );
                                })}
                                {
                                    isGeneratingAnswer &&
                                    <div>
                                        <li className={isMobileDevice ? styleMsg.Message__list__md : styleMsg.Message__list} style={{ width: 'fit-content' }}>
                                            <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                            <div>
                                                <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                                <div className={compStyle.Spacing__4} />
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <div className={styleMsg.Message__content}>
                                                        <div className={styleMsg.Message__text}>
                                                        {
                                                            clientId === 'dlst' ?
                                                            <ProgressDLST isMobileDevice={isMobileDevice} progressState={'first'} /> :    
                                                            <Progress isMobileDevice={isMobileDevice} progressState={isSecondaryReply ? 'second' : 'first'} />
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <div className={compStyle.Spacing__16}/>
                                    </div>
                                }
                                <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={isMobileDevice ? style.Chat__bottommd : style.Chat__bottom}>
                <div className={isMobileDevice ? style.Chat__inputmd : style.Chat__input}>
                    {
                        isMobileDevice &&
                        <HeaderMobile
                            setMessages={setMessages} 
                            setIsBtnVisible={setIsBtnVisible} 
                            roomId={roomId}
                            isGeneratingAnswer={isGeneratingAnswer}
                            setIsGeneratingAnswer={setIsGeneratingAnswer}
                            chatURL={chatURL}
                            clientId={clientId}
                            apiKey={apiKey}
                        />
                    }
                    <div className={
                        isMobileDevice ?
                        (!isGeneratingAnswer ? style.Chat__input1md : style.Chat__input1md__block) :
                        (!isGeneratingAnswer ? style.Chat__input1 : style.Chat__input1__block)
                    } >
                        <input
                            className={style.Chat__inputfield1}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={onChange}
                            onKeyUp={handleKeyPress}
                            type='text'
                            placeholder={!isGeneratingAnswer ? `${customSet ? customSet.nameInput : '젠투'}에게 무엇이든 물어보세요` : '답변 생성 중에는 입력하실 수 없어요'}
                            value={input}
                            aria-level='메시지'
                        />
                        <div className={style.Chat__inputButtonWrap}>
                            {
                                isGeneratingAnswer ?
                                    <CircularProgress
                                        id="LOADING"
                                        size={20}
                                        className={style.Chat__loading}
                                        aria-label="로딩 중"
                                        role="alert"
                                    /> :
                                    <button
                                        className={`${style.Chat__inputButton} send-btn`}
                                        onClick={onClick}
                                        aria-level='전송하기'
                                    >
                                        <img src={isInputFocused ? activeSendIcon : sendIcon} width={24}/>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
                <div className={isMobileDevice ? style.Chat__footer__md : style.Chat__footer} ><a href='https://www.gentooai.com'>powered by GENTOO</a></div>
            </div>
        </div>
    );
};

export default ChatFieldBase