import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import compStyle from '../../../style/styles/Components.module.css';
import style from '../../../style/styles/Chat.module.css';
import styleMsg from '../../../style/styles/Message.module.css';
import sendIcon from '../../../images/send_icon.svg';
import sendIconActive from '../../../images/send_icon_active.svg';
import IcnReset18 from '../../../images/icn_reset_18.svg';
import IcnReset24 from '../../../images/icn_reset_24.svg';
import bannerChar from '../../../images/banner_char.png';
import bannerCharSmall from '../../../images/banner_char_small.png';
import bannerIdx from '../../../images/banner_idx.svg';
import ExpandCircleUp from '../../../images/expand_circle_up.svg';
import ExpandCircleDown from '../../../images/expand_circle_down.svg';
import PriorityHigh from '../../../images/priority_high.svg';
import info from '../../../images/info.svg';
import CircularProgress from '@mui/material/CircularProgress';
import bannerImg1 from '../../../images/banner_img1.png';
import bannerImg1Book from '../../../images/banner_img1_book.png';
import bannerImg2 from '../../../images/banner_img2.svg';
import bannerImg2Book from '../../../images/banner_img2_book.svg';
import gentooSymbol from '../../../images/gentoo_symbol.png';

import Message from '../Message/MessageDemo';
import {
    randomText,
} from '../../../utils/utils';
import { 
    getChatbotReply, 
    resetQueryCondition 
} from '../../../utils/apis';
import Toggle from '../../Toggle';
import Progress from '../../Progress';
import {CarouselFAB} from '../../CarouselFAB';

const ChatFieldDemo = (props) => {
    let { chatURL, resetURL, clientId, apiKey, demoId = undefined } = props;
    const navigate = useNavigate();

    //COMM: Change page title
    document.title = '나만의 AI 쇼핑 메이트 젠투';

    let [messages, setMessages] = useState([]);
    let [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
    let [selectedBtn, setSelectedBtn] = useState('demo_ck');
    let [examLabelArr, setExamLabelArr] = useState([]);
    let [examTextArr, setExamTextArr] = useState([]);
    let [input, setInput] = useState('');
    let [isMobileDevice, setIsMobileDevice] = useState(false);
    let [randomGreeting, setRandomGreeting] = useState('');
    let [feedbackStat, setFeedbackStat] = useState(false);
    let [resetBtn, setResetBtn] = useState(false);
    let [isInputFocused, setIsInputFocused] = useState(false);
    let [minimizeFAB, setMinimizeFAB] = useState(false);
    let [windowWidth, setWindowWidth] = useState(window.innerWidth >= 1920 ? 'large' : (window.innerWidth > 1280 ? 'medium' : 'small'));
    let [isBtnVisible, setIsBtnVisible] = useState(false);
    let [isSecondaryReply, setIsSecondaryReply] = useState(false);
    let [isLimit, setIsLimit] = useState(false);

    const demoIdParam = useParams().demoId;
    const roomId = demoId || demoIdParam;
    const userId = "user" + roomId;
    const myId = roomId;
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const messageLimit = 7;
    
    const messagesEndRef = useRef(null);
    const carouselEndRef = useRef(null);
    
    let placeholder = (
        isGeneratingAnswer ? 
        '답변 생성 중에는 입력하실 수 없어요' :
        (
            messages.length < messageLimit ? 
            '젠투에게 무엇이든 물어보세요' :
            '데모 체험이 종료되었습니다.'
        )
    )

    const onClick = async () => {
        if (isGeneratingAnswer) {
            return
        }
        if (input === '') return;
        let message = [{ userId: roomId, message: input }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(input, roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
        setMessages((prev) => prev.concat({userId: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent}));
        if (chatbotReply.product && chatbotReply.product.length > 0) {
            setIsSecondaryReply(true);
            const secondaryReply = await getChatbotReply('이 제품을 추천한 이유를 설명해줘.', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
            setMessages((prev) => prev.concat({userId: 'Accio', message: secondaryReply.text, example: secondaryReply?.example, product: secondaryReply?.product, dialId: secondaryReply?.dialogueId, intent: secondaryReply?.intent}));
            setIsSecondaryReply(false);
        }
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isGeneratingAnswer) return;
            onClick();
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        setInput(e.currentTarget.value);
    };

    //COMM: Auto scroll to bottom when new message is present
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    const handleButtonClick_send = async (text) => {
        if (isGeneratingAnswer) {
            return
        }
        let message = [{ userId: roomId, message: text }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(text, roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
        setMessages((prev) => prev.concat({ userId: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent }));
        if (chatbotReply.product && chatbotReply.product.length > 0) {
            setIsSecondaryReply(true);
            const secondaryReply = await getChatbotReply('이 제품을 추천한 이유를 설명해줘.', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
            setMessages((prev) => prev.concat({userId: 'Accio', message: secondaryReply.text, example: secondaryReply?.example, product: secondaryReply?.product, dialId: secondaryReply?.dialogueId, intent: secondaryReply?.intent}));
            setIsSecondaryReply(false);
        }
    };

    //Top-toggle function
    const handleTopToggle = (e) => {
        if (isGeneratingAnswer) {
            return;
        }
        resetQueryCondition(roomId, resetURL, clientId);
        let id = e.currentTarget.dataset.id;
        switch (id) {
            case "demo_ck":
                navigate(`/demo/${roomId}/demo_ck`);
                setInput('');
                setMessages([]);
                setIsLimit(false);
                setIsBtnVisible(false);
                break;
            case "demo_fd":
                navigate(`/demo/${roomId}/demo_fd`);
                setInput('');
                setMessages([]);
                setIsLimit(false);
                setIsBtnVisible(false);
                break;
        }
    }

    const handleInputFocus = () => {
        setIsInputFocused(true);
    }

    const handleInputBlur = () => {
        setIsInputFocused(false);
    }

    const handleScroll = () => {
        if (carouselEndRef.current) {
            const rect = carouselEndRef.current.getBoundingClientRect();
            setIsBtnVisible(rect.top < (isMobileDevice ? 16 : 100));
        }
    };

    const handleResetClick = (e) => {
        if (isGeneratingAnswer) return;
        getChatbotReply('새로운 대화하기', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey);
        setMessages([]);
        setIsBtnVisible(false);
        setIsLimit(false);
    }

    window.addEventListener('resize', function() {
        setWindowWidth(window.innerWidth >= 1920 ? 'large' : (window.innerWidth > 1280 ? 'medium' : 'small'));
    })

    useEffect(() => {
        const timer = setInterval(() => {
            document.getElementById('ChatField')?.addEventListener('scroll', handleScroll());
        }, 100);
        return () => {
            clearInterval(timer);
            document.getElementById('ChatField')?.removeEventListener('scroll', handleScroll());
        }
    })

    useEffect(() => {
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages, resetBtn]);

    useEffect(() => {
        //COMM: Check whether the device is mobile or not
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobileDevice(isMobile);

        //COMM: Move VoiceOver focus to HEADER
        setTimeout(() => {
            const header = document.getElementById('HEADER');
            if (header) {
                header.tabIndex = -1;
                header.focus();
            }
        }, 250);
    }, []);

    // API request the reset of chat history at server
    useEffect(() => {
        resetQueryCondition(roomId, resetURL, clientId, apiKey);
    }, [roomId])

    useEffect(() => {
        // CTA for joining service
        if (messages.length === messageLimit) {
            setMessages((prev) => prev.concat({ userId: 'Accio', message: 'limit' }));
            setTimeout(scrollToBottom, 100);
        }

        // scroll to bottom when new msg sent
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages, resetBtn]);

    //COMM: Set greeting text
    useEffect(() => {
        let greeting_text1;
        let greeting_text2;
        greeting_text1 = `${userId}님 안녕하세요 😀 오늘은 어떤 상품을 찾고 계세요? 무엇이든 물어봐 주세요!`;
        greeting_text2 = `${userId}님 안녕하세요 🧐 어떤 상품을 찾아드릴까요? 젠투가 추천해드릴게요.`;

        const greeting_text = [greeting_text1, greeting_text2];
        const greeting_index = Math.floor(Math.random() * greeting_text.length);
        setRandomGreeting(greeting_text[greeting_index]);
    }, [userId]);

    // Top-toggle switching style
    useEffect(() => {
        const location = window.location.pathname;
        if (location.includes("demo_ck")) {
            setSelectedBtn('demo_ck');
        } else {
            setSelectedBtn('demo_fd');
        }
        randomText(setExamLabelArr, setExamTextArr, clientId);
    }, [clientId])

    useEffect(() => {
        if (messages.length >= messageLimit) setIsLimit(true);

        if (messages[messages.length - 1]?.userId === 'Accio' && !feedbackStat && !messages[messages.length - 1].message?.includes('링크:')) {
            setResetBtn(true)
        } else {
            setResetBtn(false)
        }
    }, [messages]);

    useEffect(() => {
        setTimeout(() => {setMinimizeFAB(true)}, 5000);
    }, [])

    return (
        <div style={{height: '100%'}}>
            {
                !isMobileDevice ?
                (
                    minimizeFAB ?   
                    <div className={compStyle.Banner__floating__container}>
                        {
                            windowWidth === 'small' ?
                            <div>
                                <a href='https://g2vp5qyhsnt.typeform.com/to/P2F9VHYI' style={{width: '116px'}}>
                                    <button className={compStyle.Banner__floating__minimize__small__button}>
                                        <img src={info} width={24} className={compStyle.Banner__floating__minimize__small__img} />
                                        <span className='h2'>도입 문의</span>
                                    </button>
                                </a>
                            </div> :
                            (
                                windowWidth === 'medium' ?
                                <div style={{width: '183px', height: 'fit-content', background: '#fff', borderRadius: '16px'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '16px 16px 0 16px'}}>
                                        <p className={compStyle.Banner__floating__title}>대화형 AI "젠투"가 궁금하신가요?</p>
                                        <img src={ExpandCircleUp} onClick={() => {setMinimizeFAB(false)}} style={{cursor: 'pointer'}}/>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <a href='https://g2vp5qyhsnt.typeform.com/to/P2F9VHYI' style={{width: '106px'}}>
                                            <button className={compStyle.Banner__floating__button}>
                                                <p>도입 문의하기</p>
                                            </button>
                                        </a>
                                        <img src={bannerCharSmall} width={60} />
                                    </div>
                                </div> :
                                <div className={compStyle.Banner__floating__wrap}>
                                    <div style={{padding: '16px 16px 28px 16px'}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p className={compStyle.Banner__floating__title}>대화형 AI "젠투"가 궁금하신가요?</p>
                                            <img src={ExpandCircleUp} onClick={() => {setMinimizeFAB(false)}} style={{cursor: 'pointer'}}/>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <a href='https://g2vp5qyhsnt.typeform.com/to/P2F9VHYI' style={{width: '160px'}}>
                                            <button className={compStyle.Banner__floating__button}>
                                                <p>도입 문의하기</p>
                                            </button>
                                        </a>
                                        <img src={bannerChar} width={148} />
                                    </div>
                                </div>
                            ) 
                        }
                    </div> :
                    <div className={compStyle.Banner__floating__container}>
                        <div className={compStyle.Banner__floating__wrap}>
                            <div style={{padding: '16px 16px 4px 16px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p className={compStyle.Banner__floating__title}>대화형 AI "젠투"가 궁금하신가요?</p>
                                    <img src={ExpandCircleDown} onClick={() => {setMinimizeFAB(true)}} style={{cursor: 'pointer'}}/>
                                </div>
                                <div className={compStyle.Spacing__8} />
                                <ul>
                                    <li className={compStyle.Banner__floating__content}>
                                        <img src={bannerIdx} width={18} style={{verticalAlign: 'middle', marginRight: '8px'}}/>
                                        무료로 간단한 도입 상담과 컨설팅을 진행해요.
                                    </li>
                                    <li className={compStyle.Banner__floating__content}>
                                        <img src={bannerIdx} width={18} style={{verticalAlign: 'middle', marginRight: '8px'}}/>
                                        상품 정보만 업로드하고 맞춤 데모를 받아보세요.
                                    </li>
                                    <li className={compStyle.Banner__floating__content}>
                                        <img src={bannerIdx} width={18} style={{verticalAlign: 'middle', marginRight: '8px'}}/>
                                        데모 성능을 확인하고 도입을 결정할 수 있어요.
                                    </li>
                                </ul>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <a href='https://g2vp5qyhsnt.typeform.com/to/P2F9VHYI' style={{width: '160px'}}>
                                    <button className={compStyle.Banner__floating__button}>
                                        <p>도입 문의하기</p>
                                    </button>
                                </a>
                                <img src={bannerChar} width={148} />
                            </div>
                        </div>
                    </div>              
                ) :
                (
                    minimizeFAB?
                    <div 
                        style={{
                            width: '40px', 
                            height: '40px', 
                            position: 'absolute', 
                            bottom: '102px', 
                            left: '16px', 
                            background: '#154ccb', 
                            borderRadius: '50%', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.15)'
                        }}
                        onClick={() => {setMinimizeFAB(false)}}    
                    >
                        <img src={PriorityHigh} width={18} />
                    </div> :
                    <div className={compStyle.Banner__floating__container} style={{width: '100%', right: 0}}>
                        <div className={compStyle.Banner__floating__wrap} style={{width: '100%'}}>
                            <div style={{padding: '16px 16px 4px 16px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p className={compStyle.Banner__floating__title}>대화형 AI "젠투"가 궁금하신가요?</p>
                                    <img src={ExpandCircleDown} onClick={() => {setMinimizeFAB(true)}} style={{cursor: 'pointer'}}/>
                                </div>
                                <div className={compStyle.Spacing__8} />
                                <ul>
                                    <li className={compStyle.Banner__floating__content}>
                                        <img src={bannerIdx} width={18} style={{verticalAlign: 'middle', marginRight: '8px'}}/>
                                        무료로 간단한 도입 상담과 컨설팅을 진행해요.
                                    </li>
                                    <li className={compStyle.Banner__floating__content}>
                                        <img src={bannerIdx} width={18} style={{verticalAlign: 'middle', marginRight: '8px'}}/>
                                        상품 정보만 업로드하고 맞춤 데모를 받아보세요.
                                    </li>
                                    <li className={compStyle.Banner__floating__content}>
                                        <img src={bannerIdx} width={18} style={{verticalAlign: 'middle', marginRight: '8px'}}/>
                                        데모 성능을 확인하고 도입을 결정할 수 있어요.
                                    </li>
                                </ul>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <a href='https://g2vp5qyhsnt.typeform.com/to/P2F9VHYI' style={{width: '160px'}}>
                                    <button className={compStyle.Banner__floating__button}>
                                        <p>도입 문의하기</p>
                                    </button>
                                </a>
                                <img src={bannerChar} width={148} />
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={isMobileDevice ? style.Chat__chatWrap__md : style.Chat__chatWrap}>    
                <div className={compStyle.Spacing__16}/>
                <div style={{width: '100%', maxWidth: '960px', margin: 'auto'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        {
                            isMobileDevice ?
                            <Toggle 
                                handleTopToggle={handleTopToggle} 
                                selectedBtn={selectedBtn} 
                            /> :
                            <>
                                <button className={compStyle.Button__reset} style={{visibility: 'hidden'}}/>
                                <Toggle 
                                    handleTopToggle={handleTopToggle} 
                                    selectedBtn={selectedBtn} 
                                />
                                <button 
                                    className={compStyle.Button__reset}
                                    onClick={(e) => {handleResetClick(e)}}
                                >
                                    <img src={IcnReset18} size={18} />
                                    <div className={compStyle.Spacing__4} />
                                    <p className='h6' style={{color: '#999999'}}>초기화</p>
                                </button>
                            </>
                        }
                    </div>
                </div>
                <div className={compStyle.Spacing__16}/>
                <div className={isMobileDevice ? style.Chat__chatTopWrap__mdDemo : style.Chat__chatTopWrap}>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{width: '161px', height: '0px', position: 'absolute'}}>
                            {isMobileDevice && <div className={compStyle.Spacing__16} />}
                            {isBtnVisible && <CarouselFAB carouselEndRef={carouselEndRef} />}
                        </div>
                    </div>
                    {messages.length === 0 && !isMobileDevice && (
                        <div className={style.Chat__main}>
                            <div className={style.Chat__flex}>
                                <div className={style.Util__w100mw}>
                                    <div className={style.Chat__greetingWrap}>
                                        <img src={gentooSymbol} width={32} className={style.Chat__icon} />
                                        <div>
                                            <p className='h9' style={{color: '#999999'}}>젠투</p>
                                            <div className={compStyle.Spacing__4} />
                                            <div id="HEADER" className={style.Chat__greetingContent}>
                                                <p className='h3'>
                                                    {randomGreeting}
                                                </p>
                                                <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={compStyle.Spacing__16} />
                                    <div
                                        direction="row"
                                        className={style.Chat__exampleWrap}
                                        role="region"
                                        aria-label="추천 예시"
                                    >
                                    {
                                        examLabelArr.map((data, idx) => {
                                            return (
                                                <button
                                                    key={idx}
                                                    onClick={() => {handleButtonClick_send(examTextArr[idx])}}
                                                    className={compStyle.Button__examStatement}
                                                    aria-label={examTextArr[idx]}
                                                >
                                                    <p className='h3'>{data}</p>
                                                </button>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {messages.length === 0 && isMobileDevice && (
                        <div className={style.Chat__mainmd__onboard}>
                            <div className={style.Chat__flex}>
                                <div className={style.Util__w100mw}>
                                    <div className={style.Chat__greetingWrap}>
                                        <img src={gentooSymbol} width={32} className={style.Chat__icon} />
                                        <div>
                                            <p className='h9' style={{color: '#999999'}}>젠투</p>
                                            <div className={compStyle.Spacing__4} />
                                            <div id="HEADER" className={style.Chat__greetingContent}>
                                                <p className='h3'>
                                                    {randomGreeting}
                                                </p>
                                                <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={compStyle.Spacing__16} />
                                    <div
                                        direction="row"
                                        className={style.Chat__exampleWrap}
                                        role="region"
                                        aria-label="추천 예시"
                                    >
                                    {
                                        examLabelArr.map((data, idx) => {
                                            return (
                                                <button
                                                    key={idx}
                                                    onClick={() => {handleButtonClick_send(examTextArr[idx])}}
                                                    className={compStyle.Button__examStatement}
                                                    aria-label={examTextArr[idx]}
                                                >
                                                    <p className='h3'>{data}</p>
                                                </button>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {messages.length !== 0 && (
                        <div style={{ width: '100%', height: '100%' }}>
                            <div
                                id="ChatField"
                                className={`${isMobileDevice ? style.Chat__mainmd : style.Chat__main} ${style.Chat__flex}`}
                            >
                                <div
                                    className={style.Chat__list}
                                    role="region"
                                    aria-label="대화"
                                    aria-live="polite"
                                >
                                    <div className={isMobileDevice ? style.Chat__greetingWrap__md : style.Chat__greetingWrap}>
                                        <img src={gentooSymbol} width={32} className={style.Chat__icon} />
                                        <div>
                                            <p className='h9' style={{color: '#999999'}}>젠투</p>
                                            <div className={compStyle.Spacing__4} />
                                            <div id="HEADER" className={style.Chat__greetingContent}>
                                                <p className='h3'>
                                                    {randomGreeting}
                                                </p>
                                                <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={compStyle.Spacing__16} />
                                    {messages?.map(({ userId, message, example, product, dialId, intent }, index) => {
                                        return (
                                            <div key={index} style={{filter: (isLimit && 'blur(5px)')}}>
                                                <Message
                                                    userId={userId}
                                                    myId={myId}
                                                    text={message}
                                                    clientId={clientId}
                                                    example={index === messages.length - 1 && example}
                                                    product={product}
                                                    dialId={dialId}
                                                    lastIdx={index === (messages.length - 1)}
                                                    handleButtonClick_send={handleButtonClick_send}
                                                    isMobileDevice={isMobileDevice}
                                                    carouselEndRef={carouselEndRef}
                                                    intent={intent}
                                                    messagesEndRef={messagesEndRef}
                                                    isBlur={
                                                        (messages[messages.length - 1].message === 'limit' && message !== 'limit') ?
                                                        true :
                                                        false
                                                    }
                                                >
                                                    {' '}
                                                </Message>
                                            </div>
                                        );
                                    })}
                                    {
                                        isLimit &&
                                        <>
                                            {
                                                !isMobileDevice ?
                                                <div className={compStyle.Banner__subsWrap}>
                                                    <div className={compStyle.Banner__subsWrap__img2} style={{position: 'relative'}}>
                                                        <img src={clientId === 'demo_ck' ? bannerImg2 : bannerImg2Book} width={124} style={{position: 'absolute', top: '23px', left: '502px'}} />
                                                    </div>
                                                    <div className={compStyle.Banner__subs}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <img src={clientId === 'demo_ck' ? bannerImg1 : bannerImg1Book} width={100} />
                                                            <div className={compStyle.Spacing__8} />
                                                            <p className={compStyle.Banner__subs__content}>
                                                                젠투와 함께한 {clientId === 'demo_ck' ? '쿠캣' : '푸딘코'} 추천 대화는 어떠셨나요? 
                                                                <br />
                                                                맞춤 상품을 추천해주는 대화형 AI 에이전트를 우리 쇼핑몰에도 도입해보세요!
                                                            </p>
                                                        </div>
                                                        <button className={compStyle.Button__subs}>
                                                            <a href='https://g2vp5qyhsnt.typeform.com/to/P2F9VHYI' style={{width: '100%'}}>
                                                                <p>서비스 도입 문의</p>
                                                            </a>
                                                        </button>
                                                    </div>
                                                </div> :
                                                <div className={compStyle.Banner__subsWrap__md}>
                                                    <div className={compStyle.Banner__subs__md}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <p className={compStyle.Banner__subs__content__md}>
                                                                맞춤 상품을 추천해주는 
                                                                <br />대화형 AI 에이전트 "젠투"를
                                                                <br />우리 쇼핑몰에도 도입해보세요!
                                                            </p>
                                                            <div className={compStyle.Spacing__8} />
                                                            <img src={clientId === 'demo_ck' ? bannerImg1 : bannerImg1Book} width={60} />
                                                        </div>
                                                        <div className={compStyle.Spacing__8} />
                                                        <button className={compStyle.Button__subs}>
                                                            <a href='https://g2vp5qyhsnt.typeform.com/to/P2F9VHYI' style={{width: '100%'}}>
                                                                <p>서비스 도입 문의</p>
                                                            </a>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            <div className={compStyle.Spacing__16} />
                                        </> 
                                    }
                                    {
                                        (isGeneratingAnswer && messages.length < messageLimit) &&
                                        <div>
                                            <li className={isMobileDevice ? styleMsg.Message__list__md : styleMsg.Message__list} style={{ width: 'fit-content' }}>
                                            <img src={gentooSymbol} width={32} className={style.Chat__icon} />
                                                <div>
                                                    <p className='h9' style={{color: '#999999'}}>젠투</p>
                                                    <div className={compStyle.Spacing__4} />
                                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                                        <div className={styleMsg.Message__content}>
                                                            <div className={styleMsg.Message__text}>
                                                                    <Progress isMobileDevice={isMobileDevice} progressState={isSecondaryReply ? 'second' : 'first'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <div className={compStyle.Spacing__16}/>
                                        </div>
                                    }
                                    <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={isMobileDevice ? style.Chat__bottommd : style.Chat__bottom}>
                    <div className={isMobileDevice ? style.Chat__inputmd : style.Chat__input}>
                    {
                            isMobileDevice &&
                            <div style={{display: 'flex', alignItems: 'center', paddingRight: '8px'}}>
                                <button 
                                    className={compStyle.Button__reset__md}
                                    onClick={(e) => {handleResetClick(e)}}
                                >
                                    <img src={IcnReset24} size={24} />
                                </button>
                            </div>
                        }
                        <div className={
                            isMobileDevice ?
                            (isGeneratingAnswer || messages[messages.length - 1]?.message === 'limit' ? style.Chat__input1md__block : style.Chat__input1md) :
                            (isGeneratingAnswer || messages[messages.length - 1]?.message === 'limit' ? style.Chat__input1__block : style.Chat__input1)
                        } >
                            <input
                                className={style.Chat__inputfield1}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                onChange={onChange}
                                onKeyUp={handleKeyPress}
                                type='text'
                                placeholder={placeholder}
                                value={input}
                                aria-level='메시지'
                                disabled={isGeneratingAnswer || messages[messages.length - 1]?.message === 'limit' && true}
                            />
                            <div className={style.Chat__inputButtonWrap}>
                                {
                                    isGeneratingAnswer ?
                                        <CircularProgress
                                            id="LOADING"
                                            size={20}
                                            className={style.Chat__loading}
                                            aria-label="로딩 중"
                                            role="alert"
                                        /> :
                                        <button
                                            className={style.Chat__inputButton}
                                            onClick={onClick}
                                            aria-level='전송하기'
                                        >
                                            <img src={isInputFocused ? sendIconActive : sendIcon} width={24}/>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={isMobileDevice ? style.Chat__footer__md : style.Chat__footer} ><a href='https://www.gentooai.com'>powered by GENTOO</a></div>
                </div>
            </div>
        </div>
    );
};

export default ChatFieldDemo