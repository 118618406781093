import { GET_AUTH_DATA } from "./authTypes"

const initialState = {
    udid: '',
    userToken: '',
    isRedirect: false,
}

const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_AUTH_DATA: return {
            udid: action.payload.udid,
            userToken: action.payload.userToken,
            isRedirect: action.payload.isRedirect,
        }
        default: return state
    }
}

export default authReducer