import ChatFieldBase from "../Common/ChatFieldBase";


//COMM: Main Component that contains messages, textfield, etc.
const ChatFoodinko = () => {
    return (
        <ChatFieldBase
            chatURL={process.env.REACT_APP_CUST_CHAT_FDKO_URL}
            fatchChatURL={process.env.REACT_APP_NORM_CHAT_LOAD_URL}
            resetURL={process.env.REACT_APP_CUST_CHAT_RENEW_FDKO_URL}
            apiKey={process.env.REACT_APP_X_API_KEY_FDKO}
            clientId="fdko_wine"
        />
    );
};

export default ChatFoodinko;