import { useEffect, useState } from "react";
// import { fetchAnchovyChatTest, fetchAnchovyChatbotTest, fetchAnchovyExampleTest, fetchStartingPhrase, updateAnchovyChatbotTest } from "../utils/apis";
import axios from "axios";
import * as XLSX from 'xlsx';

export const ModuleTest = () => {
    return (
        <TestModule />
    )
}

export const TestModule = () => {
    useEffect(() => {
        // fetchAnchovyChatTest();
        // fetchAnchovyChatbotTest();
        // updateAnchovyChatbotTest();
        // fetchAnchovyExampleTest();
    }, [])
}

export const TestModule1 = () => {
    const [uploaded, setUploaded] = useState(null);
    const [checkState, setCheckState] = useState([false, false]);
    const [uploadedState, setUploadedState] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    window.addEventListener('message', (event) => {
        event.preventDefault();
        console.log('message event: ', event);
    })

    const fetchChatHistory = async (requestURL, userId, clientId, channelId = '', apiKey) => {
        try {
            const response = await axios.post(
                requestURL,
                {
                    userId: userId,
                    clientId: clientId,
                    channelId: channelId,
                }, {
                    headers: {
                        'x-api-key': apiKey
                    }
                }
            )
            console.log('fetchChatHistory: ', response);
        } catch (error) {
            console.error('fetchChatHistory err: ', error);
        }
    }

    const testAPI = async (file, sideEffect = null) => {
        try {
            const response = await axios.post('https://7eqr56e710.execute-api.ap-northeast-2.amazonaws.com/prod/data/upload', 
                {
                    namespace: 'vimeal',
                    location: 'insert',
                    file: file,
                    sideEffect: sideEffect,
                }, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "x-api-key": 'LU1dhshM101iFnhTgYVjS6YSc3xgxrcd8ZCpiOyb',
                    }
                }
            )

            console.log('testAPI res, ', response.data);
        } catch (error) {
            console.error(`test API error: ${error}`)
        }
    }

    const handleXLSXupload = (e) => {
        e.preventDefault();
        const selected = e.target.files[0];
        setUploaded(selected);
        if (!checkState[1]) setCheckState([true, false]);
    }

    const readXLSX = (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: 'binary'});

            const blob = new Blob([data], { type: 'text/csv' });

            var formData = new FormData();

            // JSON 객체를 문자열로 변환하여 FormData에 추가
            formData.append('file', blob, 'data.csv');

            testAPI(formData)
        }

        reader.readAsText(file);
    }

    const importXLSX = () => {
        if (uploaded) {
            setIsCreating(true);
            readXLSX(uploaded);
        }
    }

    const postTest = async () => {
        try {
            const response = await axios.post(

            )
            console.log('postTest res, ', response.data)
        } catch (error) {
            console.error(`postTest error: ${error}`)
        }
    }

    return (
        <div
            style={{
                width: '100%',
                height : '100%',
            }}
        >
            <div
                style={{
                    width: '600px',
                    height: '100%',
                    background: '#999',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '80px',
                        background: 'skyblue',
                    }}
                >nav bar</div>
                <input type="file" id="myfile" name="myfile" accept='.csv' onChange={(e) => {handleXLSXupload(e)}} />
                <button
                    onClick={(e) => {importXLSX(e)}}
                >test start</button>
                
            </div>
        </div>
    )
}