import compStyle from '../style/styles/Components.module.css';
import icnExpandLess18 from '../images/icn_expand_less_18.svg';
import { addComma, getServiceType } from '../utils/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gtagShortcutClicked, gtagShortcutPopedUp } from '../utils/gtag';

export const ShortcutBanner = (props) => {
    const {carouselEndRef, productIdx, product, chatUserId, clientId} = props;
    const navigate = useNavigate();

    useEffect(() => {
        gtagShortcutPopedUp(chatUserId, clientId);
    }, [])

    const scrollToCarousel = () => {
        if (carouselEndRef.current) {
          carouselEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest', offset: { top: 4 } });
        }
    };

    const handleShortcutClicked = () => {
        gtagShortcutClicked(chatUserId, clientId);
    }

    return (
        <div>
            <a href={product[product?.length - 1].product[productIdx].productUrl}>
                <button 
                    className={compStyle.Button__carouselSC}
                    onClick={handleShortcutClicked}
                >
                    <div style={{width: '45px', height: '60px', borderRadius: '4px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={product[product.length - 1].product[productIdx].imageUrl} width={60} />
                    </div>
                    <div className={compStyle.Spacing__12} />
                    <div style={{width: '100%'}}>
                        <div style={{width: 'fit-content', display: 'flex'}}>
                            <img src={getServiceType(product[product.length - 1].product[productIdx].serviceType)} height={16} />
                            <div className={compStyle.Spacing__8} />
                            <p className='h9' style={{width: '100%', textAlign: 'start'}}>{product[product.length - 1].product[productIdx].name}</p>
                        </div>
                        <div className={compStyle.Spacing__4} />
                        {
                            product[product.length - 1].product[productIdx].discount !== 0 ?
                                
                                <div
                                    style={{display: 'flex' }}
                                >
                                    <p className='h7' style={{ color: "#ec3e48" }}> {product[product.length - 1].product[productIdx].discount}%</p>
                                    <div className={compStyle.Spacing__2} />
                                    <p className='h7'>{addComma(product[product.length - 1].product[productIdx].price)}원</p>
                                    <div className={compStyle.Spacing__8} />
                                    <p
                                        className='h9'
                                        style={{ textDecoration: "line-through", color: "#c0c0c0" }}
                                    >
                                        {addComma(product[product.length - 1].product[productIdx].originPrice)}
                                    </p>
                                </div> :
                                <div>
                                    <p className='h7' style={{width: '100%', textAlign: 'start'}}>
                                        {addComma(product[product.length - 1].product[productIdx].price)}원
                                    </p>
                                </div>
                        }
                        <div className={compStyle.Spacing__4} />
                        <div
                            style={{display: 'flex', alignItems: 'center'}}
                        >
                            {
                                product[product.length - 1].product[productIdx]?.tags?.map((tag, idx) => {
                                    const textColor = tag.text_color;
                                    const backgroundColor = tag.background_color;
                                    const borderColor = tag.border_color;
                                    return (
                                        <span 
                                            className='badge-font'
                                            style={{
                                                height: '18px',
                                                padding: '3px 4px',
                                                border: `1px solid ${borderColor}`,
                                                borderRadius: '3px',
                                                background: `${backgroundColor}`,
                                                color: `${textColor}`,
                                                marginRight: '4px',
                                            }}
                                        >{tag.name}</span>
                                    )
                                })
                            }
                            <span style={{ height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                <img src='/img/units/carousel-star-gray.png' width={14} />
                            </span>
                            <p className='h9' style={{ width: 'fit-content', color: '#999999', wordBreak: 'keep-all' }}>
                                {product[product.length - 1].product[productIdx].rate.toFixed(1) < 0.1 ? '신규' : `${product[product.length - 1].product[productIdx].rate.toFixed(1)} 리뷰 ${product[product.length - 1].product[productIdx].reviewNum}개`}
                            </p>
                        </div>
                    </div>
                </button>
            </a>
            <div className={compStyle.Spacing__8} />
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <button 
                    style={{width: '36px', height: '36px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #e1e1e1', borderRadius: '50%', background: '#fff', boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.15)'}}
                    onClick={scrollToCarousel}
                >
                    <img src='/img/units/shortcut-up.png' width='24' />
                </button>
            </div>
        </div>
    )


}

export const CarouselFAB = (props) => {
    const {carouselEndRef} = props;

    const scrollToCarousel = () => {
        if (carouselEndRef.current) {
          carouselEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest', offset: { top: 4 } });
        }
    };

    return (
        <button 
            className={compStyle.Button__carouselFAB}
            onClick={scrollToCarousel}
        >
            <span className='h7'>최근 추천으로 돌아가기</span>
            <img src={icnExpandLess18} size={18} style={{verticalAlign: 'middle'}}/>
        </button>
    )


}