import { useState, useEffect, useRef } from 'react';
import compStyle from '../../style/styles/Components.module.css';
import style from '../../style/styles/Chat.module.css';
import styleMsg from '../../style/styles/Message.module.css';
import sendIcon from '../../images/send_icon.svg';
import sendIconActive from '../../images/send_icon_active.svg';
import CircularProgress from '@mui/material/CircularProgress';

import Message from './MessageTextBase';
import { fetchChatbotInfo, getChatbotReply, getChatbotReplyAnchovy, resetQueryConditionAnchovy } from '../../utils/apis';
import { CarouselFAB, ShortcutBanner } from '../CarouselFAB';
import { useSelector } from 'react-redux';
import { Header, HeaderMobile } from '../Header/Header';
import { initSettings } from '../../data/initSetting';
import { Progress, ProgressDLST } from '../Chat/Skeleton';
import { convertCustomSet } from '../ParseCustomSet';
import { gtagSendMessage } from '../../utils/gtag';
import ExampleGroup from '../Chat/Example';
import gentooSymbol from '../../images/gentoo_symbol.png';

const ChatFieldBaseTest = (props) => {
    let { customProperty, reqParams } = props;
    const chatbotURL = customProperty.apiUrls.chatbotURL;
    const chatURL = customProperty.apiUrls.chatURL;
    const exampleURL = customProperty.apiUrls.exampleURL;
    const resetURL = customProperty.apiUrls.resetURL;
    const apiKey = customProperty.apiKey;
    const chatUserId = reqParams.chatUserId;
    const partnerId = reqParams.partnerId;
    const partnerType = reqParams.partnerType;
    // const requestChatFunc = getChatbotReplyAnchovy;

    //COMM: Change page title
    document.title = '나만의 AI 쇼핑 메이트 젠투';
    
    let [messages, setMessages] = useState([]);
    let [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
    let [examLabelArr, setExamLabelArr] = useState([]);
    let [examTextArr, setExamTextArr] = useState([]);
    let [input, setInput] = useState('');
    let [isMobileDevice, setIsMobileDevice] = useState(false);
    let [isMobileView, setIsMobileView] = useState(reqParams.isMobile || window.innerWidth < 601);
    let [randomGreeting, setRandomGreeting] = useState('');
    let [resetBtn, setResetBtn] = useState(false);
    let [isInputFocused, setIsInputFocused] = useState(false);
    let [isBtnVisible, setIsBtnVisible] = useState(false);
    let [isSecondaryReply, setIsSecondaryReply] = useState(false);
    let [productIdx, setProductIdx] = useState(0);
    let [isBSActive, setIsBSActive] = useState(false);
    let [isChatStarted, setIsChatStarted] = useState(false);
    let [isMoreEnabled, setIsMoreEnabled] = useState(false);
    const [customSet, setCustomSet] = useState(initSettings);
    const getChatbotReplyFunc = getChatbotReplyAnchovy;

    document.documentElement.style.setProperty('--color-prim-800', customSet.colorInput.color0);
    document.documentElement.style.setProperty('--color-prim-200', customSet.colorInput.color1);
    document.documentElement.style.setProperty('--color-prim-100', customSet.colorInput.color2);

    // const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const messagesEndRef = useRef(null);
    const carouselEndRef = useRef(null);
    const inputRef = useRef(null);

    const onClick = async () => {
        if (isGeneratingAnswer) return;
        if (input === '') return;
        if (isBSActive) return;
        const itemId = sessionStorage.getItem('itemId');
        const userCount = messages.filter(item => item.user !== 'Accio').length;
        let message = [{id: userCount, user: chatUserId, message: input, time: new Date().toString() }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReplyFunc(
            input, 
            chatUserId, 
            setIsGeneratingAnswer, 
            chatURL, 
            partnerType, 
            apiKey, 
            itemId, 
            reqParams.isMobile, 
            partnerId
        );
        setMessages((prev) => prev.concat({id: userCount+1, user: 'Accio', message: chatbotReply.text, example: chatbotReply?.example || ['비슷한 상품 더 추천해줘', '이 상품의 특장점은 뭐야?'], product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent, userGroup: chatbotReply?.userGroup}));

        gtagSendMessage(userCount, "Manual Input", input, partnerId)
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isGeneratingAnswer) return;
            onClick();
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        if (isGeneratingAnswer) return;
        if (isBSActive) return;
        setInput(e.currentTarget.value);
    };

    //COMM: Auto scroll to bottom when new message is present
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    const handleButtonClick_send = async (e, text, itemId = 'general') => {
        e.stopPropagation();
        if (isGeneratingAnswer) return;
        const userCount = messages.filter(item => item.user !== 'Accio').length;
        let message = [{id: userCount,  user: chatUserId, message: text }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReplyFunc(
            text, 
            chatUserId, 
            setIsGeneratingAnswer, 
            chatURL, 
            partnerType, 
            apiKey, 
            itemId, 
            reqParams.isMobile, 
            partnerId
        );
        setMessages((prev) => prev.concat({id: userCount+1, user: 'Accio', message: chatbotReply.text,  example: ['비슷한 상품 더 추천해줘', '이 상품의 특장점은 뭐야?'], product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent }));

        gtagSendMessage(userCount, "Example Message Click", text, partnerType);
    };

    const sendInputFocusStatus = (state) => {
        const targetWindow = window.parent;
        const inputFocusState = {
            inputFocusState: state,
        }
        targetWindow.postMessage(inputFocusState, "*");
    }

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
        sendInputFocusStatus(true);
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendInputFocusState) {
            window.webkit.messageHandlers.sendInputFocusState.postMessage(`input focused`);
        } else {
            console.warn('webkit.messageHandlers is not available');
        }

         // AOS SDK로 메시지 전달
        if (window.Android && window.Android.sendInputFocusState) {
            window.Android.sendInputFocusState('input focused');
        } else {
            console.warn('Android interface is not available');
        }
        
        if (!isChatStarted) {
            setIsChatStarted(true);
        }
    }

    const handleInputBlur = () => {
        setIsInputFocused(false);
        sendInputFocusStatus(false);
    }

    const handleScroll = () => {
        if (carouselEndRef.current) {
            const rect = carouselEndRef.current.getBoundingClientRect();
            setIsBtnVisible(rect.top < (isMobileDevice ? 16 : 100));
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            document.getElementById('ChatField')?.addEventListener('scroll', handleScroll());
        }, 100);
        return () => {
            clearInterval(timer);
            document.getElementById('ChatField')?.removeEventListener('scroll', handleScroll());
        }
    })

    useEffect(() => {
        // scroll to bottom when new msg sent
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages, resetBtn]);

    useEffect(() => {
        //COMM: Check whether the device is mobile or not
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobileDevice(isMobile);

        //COMM: Move VoiceOver focus to HEADER
        setTimeout(() => {
            const header = document.getElementById('HEADER');
            if (header) {
                header.tabIndex = -1;
                header.focus();
            }
        }, 250);
    }, []);

    useEffect(() => {
        fetchChatbotInfo(chatbotURL, partnerId)
            .then(res => convertCustomSet(res))
            .then(res => {setCustomSet(res)})
    },[])

    // API request the reset of chat history at server
    useEffect(() => {
        resetQueryConditionAnchovy(chatUserId, resetURL, partnerId, partnerType, apiKey);
    }, [chatUserId]);

    useEffect(() => {
        if (messages[messages.length - 1]?.user === 'Accio' && !messages[messages.length - 1].product) {
            setResetBtn(true)
        } else {
            setResetBtn(false)
        }
    }, [messages]);

    return (
        <div className={isMobileView ? style.Chat__chatWrap__md : style.Chat__chatWrap}>
            {
                !isMobileView &&
                <Header 
                    setMessages={setMessages} 
                    setIsBtnVisible={setIsBtnVisible} 
                    roomId={chatUserId}
                    isGeneratingAnswer={isGeneratingAnswer}
                    setIsGeneratingAnswer={setIsGeneratingAnswer}
                    chatURL={chatURL}
                    clientId={partnerType}
                    partnerId={partnerId}
                    apiKey={apiKey}
                    isMobileDevice={reqParams.isMobile}
                />
            }
            <div className={isMobileView ? style.Chat__chatTopWrap__md : style.Chat__chatTopWrap}>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width: (partnerType === 'dlst' ? '100%' : '161px'), height: '0px', position: 'absolute'}}>
                        {(isMobileView && partnerType !== 'dlst') && <div className={compStyle.Spacing__16} />}
                        {
                            (isBtnVisible && partnerType === 'dlst') &&
                            <ShortcutBanner 
                                carouselEndRef={carouselEndRef} 
                                productIdx={productIdx}
                                product={messages.filter(message => message.intent === '제공')}
                                chatUserId={chatUserId}
                                clientId={partnerType}
                            />
                        }
                        {
                            (isBtnVisible && partnerType !== 'dlst') &&
                            <CarouselFAB 
                                carouselEndRef={carouselEndRef} 
                            />
                        }
                    </div>
                </div>
                {messages.length === 0 && !isMobileView && (
                    <div className={style.Chat__main}>
                        <div className={style.Chat__flex}>
                            <div className={style.Util__w100mw}>
                                <div className={style.Chat__greetingWrap}>
                                    <img src={customSet.logoInput} width={32} height={32} className={style.Chat__icon} />
                                    <div>
                                        <div>
                                            <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                            <div className={compStyle.Spacing__4} />
                                            <div id="HEADER" className={style.Chat__greetingContent}>
                                                <p className='h3'>
                                                    {customSet.greetingInput}
                                                </p>
                                                {/* <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                <ExampleGroup
                                    clientId={partnerType}
                                    examples={customSet.example}
                                    size={'large'}
                                    handleButtonClick_send={handleButtonClick_send}
                                    variant={'A'}
                                    // variant={variant}
                                />
                                <div className={compStyle.Spacing__8} />
                            </div>
                        </div>
                    </div>
                )}
                {messages.length === 0 && isMobileView && (
                    <div className={style.Chat__mainmd__onboard}>
                        <div className={compStyle.Spacing__16} />
                        <div className={style.Chat__flex}>
                            <div className={style.Util__w100mw}>
                                <div className={style.Chat__greetingWrap}>
                                    <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} height={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p className='h3'>
                                                {customSet.greetingInput}
                                            </p>
                                            {/* <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                <ExampleGroup
                                    clientId={partnerType}
                                    examples={customSet.example}
                                    size={'large'}
                                    handleButtonClick_send={handleButtonClick_send}
                                    variant={'A'}
                                    // variant={variant}
                                />
                                <div className={compStyle.Spacing__8} />
                            </div>
                        </div>
                    </div>
                )}
                {messages.length !== 0 && (
                    <div style={{ width: '100%', height: '100%' }}>
                        <div
                            id="ChatField"
                            className={`${isMobileView ? style.Chat__mainmd : style.Chat__main} ${style.Chat__flex}`}
                        >
                            <div
                                className={style.Chat__list}
                                role="region"
                                aria-label="대화"
                                aria-live="polite"
                            >
                                {isMobileView && <div className={compStyle.Spacing__16} />}
                                <div className={isMobileView ? style.Chat__greetingWrap__md : style.Chat__greetingWrap}>
                                    <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} height={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p className='h3'>
                                                {customSet.greetingInput}
                                            </p>
                                            {/* <p className='h9' style={{color: '#999', marginTop: '4px'}}>(체험용 데모 페이지로, 상품 정보 및 연동되는 링크가 불완전할 수 있어요.)</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                {messages?.map(({ id, user, message, example, product, dialId, intent, time, userGroup }, index) => {
                                    return (

                                        <Message
                                            key={index}
                                            id={id}
                                            user={user}
                                            chatUserId={chatUserId}
                                            partnerId={partnerId}
                                            clientId={partnerType}
                                            text={message}
                                            example={index === messages.length - 1 && example}
                                            product={product}
                                            dialId={dialId}
                                            lastIdx={index === (messages.length - 1)}
                                            handleButtonClick_send={handleButtonClick_send}
                                            isMobileDevice={reqParams.isMobile}
                                            carouselEndRef={carouselEndRef}
                                            time={time}
                                            customSet={customSet}
                                            userGroup={userGroup}
                                            setMessages={setMessages}
                                            setResetBtn={setResetBtn}
                                            intent={intent}
                                            messagesEndRef={messagesEndRef}
                                            productIdx={productIdx}
                                            setProductIdx={setProductIdx}
                                            isMobileView={isMobileView}
                                        >
                                            {' '}
                                        </Message>
                                    );
                                })}
                                {
                                    isGeneratingAnswer &&
                                    <div>
                                        <li className={isMobileView ? styleMsg.Message__list__md : styleMsg.Message__list} style={{ width: 'fit-content' }}>
                                            <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} height={32} className={style.Chat__icon} />
                                            <div>
                                                <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                                <div className={compStyle.Spacing__4} />
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <div className={styleMsg.Message__content}>
                                                        <div className={styleMsg.Message__text}>
                                                            <ProgressDLST partnerType={partnerType} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <div className={compStyle.Spacing__16}/>
                                    </div>
                                }
                                <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={isMobileView ? style.Chat__bottommd : style.Chat__bottom} style={{borderTop: '1px solid #eee'}}>
                <div className={isMobileView ? style.Chat__inputmd : style.Chat__input}>
                    {
                        isMobileView &&
                        <HeaderMobile
                            setMessages={setMessages} 
                            setIsBtnVisible={setIsBtnVisible} 
                            roomId={chatUserId}
                            isGeneratingAnswer={isGeneratingAnswer}
                            setIsGeneratingAnswer={setIsGeneratingAnswer}
                            chatURL={chatURL}
                            clientId={partnerType}
                            apiKey={apiKey}
                            isBSActive={isBSActive}
                            partnerId={partnerId}
                            isMobileDevice={reqParams.isMobile}
                        />
                    }
                    <div className={
                        isMobileView ?
                        (!isGeneratingAnswer ? style.Chat__input1md : style.Chat__input1md__block) :
                        (!isGeneratingAnswer ? style.Chat__input1 : style.Chat__input1__block)
                    } >
                        <input
                            id='chat-input'
                            className={style.Chat__inputfield1}
                            ref={inputRef}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={onChange}
                            onKeyUp={handleKeyPress}
                            type='text'
                            placeholder={!isGeneratingAnswer ? `${customSet.nameInput}에게 무엇이든 물어보세요` : '답변 생성 중에는 입력하실 수 없어요'}
                            value={input}
                            aria-level='메시지'
                        />
                        <div className={style.Chat__inputButtonWrap}>
                            {
                                isGeneratingAnswer ?
                                    <CircularProgress
                                        id="LOADING"
                                        size={20}
                                        className={style.Chat__loading}
                                        aria-label="로딩 중"
                                        role="alert"
                                    /> :
                                    <button
                                        className={`${style.Chat__inputButton} send-btn`}
                                        onClick={onClick}
                                        aria-level='전송하기'
                                    >
                                        <img src={isInputFocused ? sendIconActive : sendIcon} width={24}/>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
                <div className={isMobileView ? style.Chat__footer__md : style.Chat__footer} ><a href='https://www.gentooai.com'>powered by GENTOO</a></div>
            </div>
        </div>
    );
};

export default ChatFieldBaseTest