export const initSettings = {
    logoInput: '/img/units/gentoo-symbol.png',
    nameInput: '젠투',
    colorInput: {
        color0: `rgba(21, 76, 202, 1)`,
        color1: `rgba(187, 200, 229, 1)`,
        color2: `rgba(224, 230, 243, 1)`, 
    },
    greetingInput: '안녕하세요 🧐 어떤 상품을 찾아드릴까요? 젠투가 추천해드릴게요.',
    greetingSubInput: '',
    recommendSizeInput: 'multi',
    carouselDirInput: 'carousel-vertical',
    exceptKeyword: [],
    example: [],
};

export const initSettingsCkat = {
    logoInput: '/img/ckat/cookat-profile.png',
    nameInput: '쿠키',
    colorInput: {
        color0: `rgba(255, 88, 51, 1)`,
        color1: `rgba(253, 208, 198, 1)`,
        color2: `rgba(255, 238, 234, 1)`, 
    },
    greetingInput: '안녕하세요👋 저는 맛잘알 가이드 쿠키에요! 궁금한 상품 정보, 필요한 상황에 어울리는 상품 추천까지 도와드릴게요!',
    greetingSubInput: '',
    recommendSizeInput: 'multi',
    carouselDirInput: 'carousel-vertical',
    exceptKeyword: [],
    example: [],
};

export const initSettingsViml = {
    logoInput: '/img/viml/viml-profile.png',
    nameInput: 'AI상담사',
    colorInput: {
        color0: `rgba(0, 165, 253, 1)`,
        color1: `rgba(0, 102, 203, 1)`,
        color2: `rgba(224, 244, 255, 1)`, 
    },
    greetingInput: '안녕하세요, 고객님😄 찾으시는 영양제 있으신가요? 말씀하시면 제가 추천 제품을 찾아드릴게요!',
    greetingSubInput: '',
    recommendSizeInput: 'multi',
    carouselDirInput: 'carousel-horizontal',
    exceptKeyword: [],
    example: [],
};

export const initSettingsDlst = {
    logoInput: '/img/units/gentoo-symbol-dlst.png',
    nameInput: '술 전문가 젠투',
    colorInput: {
        color0: `rgba(254, 80, 0, 1)`,
        color1: `rgba(255, 197, 177, 1)`,
        color2: `rgba(255, 235, 228, 1)`, 
    },
    greetingInput: '안녕하세요 🧐 어떤 상품을 찾아드릴까요? 젠투가 추천해드릴게요.',
    greetingSubInput: '',
    recommendSizeInput: 'multi',
    carouselDirInput: 'carousel-horizontal',
    // carouselDirInput: 'carousel-vertical',
    exceptKeyword: [],
    example: [],
};