import { initSettingsViml } from "../../data/initSetting";
import ChatFieldBase from "../Common/ChatFieldBase";


//COMM: Main Component that contains messages, textfield, etc.
const ChatVimil = (props) => {

    return (
        <ChatFieldBase
            chatURL={process.env.REACT_APP_CUST_CHAT_VIML_URL}
            resetURL={process.env.REACT_APP_CUST_CHAT_RENEW_VIML_URL}
            loadURL={process.env.REACT_APP_CUST_LOAD_VIML_URL}
            apiKey={process.env.REACT_APP_X_API_KEY_VIML}
            clientId="viml"
            customSet={initSettingsViml}
        />
    );
};

export default ChatVimil;