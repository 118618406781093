import { useParams } from 'react-router-dom';
import ChatFieldDemo from './ChatFieldDemo.jsx'

//COMM: Main Component that contains messages, textfield, etc.
const ChatDemo = () => {
	const clientId = useParams().categoryId;

    return (
        <>
            <ChatFieldDemo 
                chatURL={process.env.REACT_APP_DEMO_CHAT_URL}
                resetURL={process.env.REACT_APP_DEMO_RENEW_URL}
                apiKey={process.env.REACT_APP_X_API_KEY_DEMO}
                clientId={clientId}
            />
        </>
    );
};

export default ChatDemo;