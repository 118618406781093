import compStyle from '../../style/styles/Components.module.css';
import IcnReset18 from '../../images/icn_reset_18.svg';
import IcnReset24 from '../../images/icn_reset_24.svg';
import IcnResetInactive24 from '../../images/icn_reset_inactive_24.svg';
import { getChatbotReply, getChatbotReplyAnchovy } from '../../utils/apis';

export const Header = (props) => {
    const {
        setMessages, 
        setIsBtnVisible,
        roomId,
        isGeneratingAnswer,
        setIsGeneratingAnswer,
        chatURL,
        clientId,
        apiKey,
        isMobileDevice,
        partnerId,
    } = props;

    console.log('header props, ', props);
    
    const partnerType = clientId;
    const chatUserId = roomId;

    const handleResetClick = (e) => {
        if (isGeneratingAnswer) return;
        if (clientId === 'dlst') {
            console.log('hreeee?')
            getChatbotReply('새로운 대화하기', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey, 'general', isMobileDevice, partnerId);
        } else {
            console.log('thereeee?', partnerId)
            getChatbotReplyAnchovy('새로운 대화하기', chatUserId, setIsGeneratingAnswer, chatURL, partnerType, apiKey, 'general', isMobileDevice, partnerId, 1);
        }   
        setMessages([]);
        setIsBtnVisible(false);
        sessionStorage.setItem('itemId', 'general');

        window.gtag('event', 'RefreshClick', {
            event_category: 'InitClicked',
            event_label: 'User initiate chat history',
            userId: chatUserId,
            clientId: clientId,
        })
    }

    return (
        <>
            <div className={compStyle.Spacing__16}/>
            <div style={{width: '100%', maxWidth: '960px', margin: 'auto'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <button className={compStyle.Button__reset} style={{visibility: 'hidden'}}/>
                    <button className={compStyle.Button__reset} style={{visibility: 'hidden'}}/>
                    <button 
                        className={compStyle.Button__reset}
                        onClick={(e) => {handleResetClick(e)}}
                    >
                        <img src={IcnReset18} size={18} />
                        <div className={compStyle.Spacing__4} />
                        <p className='h6' style={{color: '#999999'}}>초기화</p>
                    </button>
                </div>
            </div>
            <div className={compStyle.Spacing__16}/>
        </>
    )
}

export const HeaderMobile = (props) => {
    const {
        setMessages, 
        setIsBtnVisible,
        roomId,
        isGeneratingAnswer,
        setIsGeneratingAnswer,
        chatURL,
        clientId,
        apiKey,
        isBSActive,
        isMobileDevice,
        partnerId,
    } = props;
    
    const partnerType = clientId;
    const chatUserId = roomId;
    
    const handleResetClick = (e) => {
        if (isGeneratingAnswer) return;
        if (isBSActive) return;
        if (clientId === 'dlst') {
            getChatbotReply('새로운 대화하기', roomId, setIsGeneratingAnswer, chatURL, clientId, apiKey, 'general', isMobileDevice, partnerId);
        } else {
            getChatbotReplyAnchovy('새로운 대화하기', chatUserId, setIsGeneratingAnswer, chatURL, partnerType, apiKey, 'general', isMobileDevice, partnerId, 1);
        }  
        setMessages([]);
        setIsBtnVisible(false);
        sessionStorage.setItem('itemId', 'general');

        window.gtag('event', 'RefreshClick', {
            event_category: 'InitClicked',
            event_label: 'User initiate chat history',
            userId: chatUserId,
            clientId: clientId,
        })
    }
    
    return (
        <div style={{display: 'flex', alignItems: 'center', paddingRight: '8px'}}>
            {
                clientId !== 'dlst' ?
                <button 
                    className={compStyle.Button__reset__md}
                    onClick={(e) => {handleResetClick(e)}}
                >
                <img src={isGeneratingAnswer ? IcnResetInactive24 : IcnReset24} size={24} /> 
                </button> :
                <div 
                    
                    onClick={(e) => {handleResetClick(e)}}
                >
                    <img src={isBSActive || isGeneratingAnswer ? '/img/units/input-reset-inactive.png' : '/img/units/input-reset-active.png'} width={36} height={36} />
                </div>
            }
        </div>
    )
}