import { GET_INIT_EXAM_DATA } from "./testTypes"


const initialState = {
    example: []
}

const testReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_INIT_EXAM_DATA: return {
            example: action.payload.example,
        }
        default: return state
    }
}

export default testReducer