
export const gtagSendMessage = (userCount, eventLabel, input, clientId) => {
    if (userCount === 0) {
        window.gtag('event', 'FirstChat', {
            event_category: 'Message',
            event_label: eventLabel,
            message: input,  // message content
            clientId: clientId,
        });
    } else if (userCount === 1) {
        window.gtag('event', 'SecondChat', {
            event_category: 'Message',
            event_label: eventLabel,
            message: input,  // message content
            clientId: clientId,
        });
    } else if (userCount === 2) {
        window.gtag('event', 'ThirdChat', {
            event_category: 'Message',
            event_label: eventLabel,
            message: input,  // message content
            clientId: clientId,
        });
    } else {
        window.gtag('event', 'SendMessage', {
            event_category: 'Message',
            event_label: eventLabel,
            message: input,  // message content
            clientId: clientId,
        });
    }
}

export const gtagCarouselClick = (idx, name, clientId, userGroup) => {
    if (idx === 0) {
        window.gtag('event', 'RecommendationClick', {
            event_category: 'CarouselClicked',
            event_label: 'User clicked recommended product',
            product_name: name,
            product_idx: idx,
            clientId: clientId,
            userGroup: userGroup,
        })
    } else {
        window.gtag('event', 'clicked', {
            event_category: 'CarouselClicked',
            event_label: 'User clicked relative product',
            product_name: name,
            product_idx: idx,
            clientId: clientId,
            userGroup: userGroup,
        })
    }
}

export const gtagClickedOption = (id, idx, name, clientId, userGroup) => {
    if (id === 1) {
        window.gtag('event', 'FirstOption', {
            event_category: 'CarouselClicked',
            event_label: 'User clicked product at first dial',
            product_name: name,
            product_idx: idx,
            clientId: clientId,
            userGroup: userGroup,
        })
    } else if (id === 2) {
        window.gtag('event', 'SecondOption', {
            event_category: 'CarouselClicked',
            event_label: 'User clicked product at second dial',
            product_name: name,
            product_idx: idx,
            clientId: clientId,
            userGroup: userGroup,
        })
    } else if (id === 3) {
        window.gtag('event', 'ThirdOption', {
            event_category: 'CarouselClicked',
            event_label: 'User clicked product at third dial',
            product_name: name,
            product_idx: idx,
            clientId: clientId,
            userGroup: userGroup,
        })
    }
}

export const gtagCarouselScroll = (name) => {
    window.gtag('event', 'CarouselScroll', {
        event_category: 'Scroll',
        event_label: 'Scroll Half Width Reached',
        product_name: name,
    })
}

export const gtagTabFeedback = (name) => {
    window.gtag('event', 'TabFeedback', {
        event_category: 'Feedback',
        event_label: 'Feedback Button Click',
        product_name: name, // itemId 값을 사용
    });
}

export const gtagSendFeedback = (name) => {
    window.gtag('event', 'SendFeedback', {
        event_category: 'Feedback',
        event_label: 'Send Feedback Button Click',
        product_name: name, // itemId 값을 사용
    });
}

export const gtagGentooResponse = (message, userId, clientId, intent) => {
    window.gtag('event', 'GentooUtterance', {
        event_category: 'GentooResponse',
        event_label: 'gentoo responsed',
        message: message,
        userId: userId,
        clientId: clientId,
        offer_failure: intent === '제공불가',
    })
}

export const gtagRecommendProduct = (message, userId, clientId) => {
    window.gtag('event', 'Recommend', {
        event_category: 'GentooResponse',
        event_label: 'gentoo recommended products',
        message: message,
        userId: userId,
        clientId: clientId,
    })
}

export const gtagShortcutPopedUp = (userId, clientId) => {
    window.gtag('event', 'ShortcutPopedUp', {
        event_category: 'ShortcutPopedUp',
        event_label: 'carousel shortcut poped up',
        userId: userId,
        clientId: clientId,
    })
}

export const gtagShortcutClicked = (userId, clientId) => {
    window.gtag('event', 'ShortcutClicked', {
        event_category: 'ShortcutClicked',
        event_label: 'carousel shortcut clicked',
        userId: userId,
        clientId: clientId,
    })
}

export const gtagOpenBottomSheet = (userId, clientId) => {
    window.gtag('event', 'OpenBottomSheet', {
        event_category: 'OpenBottomSheet',
        event_label: 'bottom sheet opened',
        userId: userId,
        clientId: clientId,
    })
}

export const gtagCloseBottomSheet = (userId, clientId, duration) => {
    // for tracking BS session time
    window.gtag('event', 'BottomSheetDuration', {
        event_category: 'BottomSheetDuration',
        event_label: 'bottom sheet session time',
        userId: userId,
        clientId: clientId,
        duration: duration,
    })
}

export const gtagClickBSProduct = (userId, clientId, itemId, itemName) => {
    window.gtag('event', 'ClickedBSProduct', {
        event_category: 'ClickedBSProduct',
        event_label: 'clicked bottom sheet product',
        userId: userId,
        clientId: clientId,
        itemId: itemId,
        itemName: itemName,
    })
}

export const gtagClickBSKeyword = (userId, clientId, keyword) => {
    window.gtag('event', 'ClickedBSKeyword', {
        event_category: 'ClickedBSKeyword',
        event_label: 'clicked bottom sheet keyword',
        userId: userId,
        clientId: clientId,
        keyword: keyword,
    })
}

export const gtagClickPickup = (name, clientId, userGroup) => {
    window.gtag('event', 'PickupButtonClicked', {
        event_category: 'PickupClicked',
        event_label: 'User clicked pickup button',
        product_name: name,
        clientId: clientId,
        userGroup: userGroup,
    })
}