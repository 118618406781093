export const progressDataObj = {
    default: [
        {type: 'gentoo', text: '젠투, 상품 정보 읽어보며 고르는 중..'},
        {type: 'gentoo', text: '젠투, 더 좋은 추천을 위해 상품 페이지 읽는 중..'},
        {type: 'gentoo', text: '젠투, 상품 세부 정보를 읽어가며 추천을 준비 중..'},
        {type: 'gentoo', text: '젠투, 대화 내용에 맞는 상품을 골라내는 중..'},
        {type: 'gentoo', text: '젠투, 최적의 상품을 낚아내기 위해 얼음 속을 헤집는 중..'},
        {type: 'gentoo', text: '젠투, 바다 깊은 곳에 위치한 상품까지 찾는 중..'},
        {type: 'gentoo', text: '젠투, 상품을 찾기 위해 남극까지 다녀오는 중..'},
        {type: 'gentoo', text: '젠투, 다른 펭귄들에게 어떤 상품이 좋았는지 물어보는 중..'},
        {type: 'gentoo', text: '펭귄은 날지 못하지만 물속에서 시속 10km로 수영할 수 있어요.'},
        {type: 'gentoo', text: '펭귄은 날지 못하지만 최대 수심 500미터까지 잠수할 수 있어요.'},
        {type: 'gentoo', text: '펭귄도 6천만 년 전에는 하늘을 날 수 있었어요.'},
        {type: 'gentoo', text: '“갈라파고스 펭귄”은 유일하게 적도 근처에 사는 펭귄이에요.'},
        {type: 'gentoo', text: '펭귄은 서로 다른 목소리를 인식해서 수천 마리 중에서도 가족을 찾을 수 있어요.'},
        {type: 'gentoo', text: '“젠투 펭귄”은 가장 빠르게 헤엄치는 펭귄으로, 시속 36km까지 도달할 수 있어요.'},
        {type: 'info', text: '입력창 왼쪽의 “새로고침” 버튼을 누르면 새로운 대화를 시작할 수 있어요.'},
        {type: 'info', text: '추천 상품이 마음에 들지 않는 이유를 알려주시면, 더 나은 추천이 가능해요.'},
        {type: 'info', text: '젠투는 아직 반품, 주문취소와 같은 고객센터 업무에는 익숙하지 않아요.'},
        {type: 'info', text: '젠투는 현재 쇼핑몰에서 판매 중인 상품만 추천해 드릴 수 있어요.'},
        {type: 'info', text: '“돼지고기와 먹을 와인 추천해줘”처럼 구체적으로 알려주시면 좋아요.'},
        {type: 'info', text: '“눈이 침침할 때 먹을 영양제”처럼 구체적으로 알려주시면 좋아요.'},
    ],
    dlst: [
        {type: 'gentoo', text: '젠투가 얼음조각 밑을 확인 중 …'},
        {type: 'gentoo', text: '스노클링하며 얼음 속을 헤집는 중…'},
        {type: 'gentoo', text: '눈밭을 날개로 뒤적뒤적하는 중…'},
        {type: 'gentoo', text: '눈사람에게 행방을 물어보는 중….'},
        {type: 'gentoo', text: '최신 기술을 동원해 제품을 찾는 중 …'},
        {type: 'gentoo', text: '대서양을 가로지르며 초고속으로 헤엄치는 중…'},
        {type: 'gentoo', text: '젠투 펭귄은 최대 시속 36km까지 수영할 수 있는 펭귄이에요'},
        {type: 'gentoo', text: '펭귄도 6천만 년 전에는 하늘을 날 수 있었어요'},
        {type: 'gentoo', text: '젠투, 열심히 머리 굴리는 중…'},
        {type: 'gentoo', text: '젠투, 안 쓰던 머리 갑자기 쓰는 중…'},
        {type: 'gentoo', text: '젠투, 열심히 상품 검색 중…'},
        {type: 'gentoo', text: '맥주는 차갑게 마셔야 제 맛을 느낄 수 있어요!'},
        {type: 'gentoo', text: '와인과 치즈는 완벽한 페어링이죠!'},
        {type: 'gentoo', text: '젠투, 술 향에 취하는 중..'},
        {type: 'gentoo', text: '와인과 초콜릿은 달콤한 조합이죠!'},
        {type: 'gentoo', text: '주류 리스트를 꼼꼼히 읽느라 시간이 걸려요..'},
        {type: 'info', text: '인류가 언제 처음 술을 마셨는지 아시나요?'},
        {type: 'info', text: '인류가 처음 술을 마신 시기는 아무도 알 수 없습니다.'},
        {type: 'info', text: '메소포타미아와 이집트 기록에 포도주가 등장합니다.'},
        {type: 'info', text: '세계 3대 위스키 제조국은 스코틀랜드, 아일랜드, 미국이에요.'},
        {type: 'info', text: '위스키는 Whisky 일까요, Whiskey 일까요?'},
        {type: 'info', text: '위스키의 어원은 ‘생명의 물’이라는 뜻의 게일어로 ‘uisge beatha’예요.'},
        {type: 'info', text: '알코올이 1% 미만일 경우 비알코올로 분류됩니다.'},
        {type: 'info', text: '알코올이 전혀 없을 경우 무알코올로 분류됩니다.'},
        {type: 'info', text: '비알코올과 무알코올은 성인용으로 판매됩니다.'},
        {type: 'info', text: '세계적으로 유명한 와인 생산국은 프랑스와 이탈리아입니다.'},
        {type: 'info', text: 'Vivino는 사용자 리뷰를 기반으로 와인을 평가합니다.'},
        {type: 'info', text: 'Vivino는 세계 최대의 와인 커뮤니티입니다.'},
    ],
};