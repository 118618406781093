import { useState } from 'react';
import compStyle from '../../style/styles/Components.module.css';
import { requestLogin } from '../../utils/apis';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await requestLogin(email, password);
        if (response) navigate('/test');
        else {alert('계정 정보가 올바르지 않습니다.')}
    }

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{width: "100%", maxWidth: "440px"}}>
                <div className={compStyle.Spacing__56} />
                <p className="h1" style={{fontSize: '24px'}}>젠투에서 사용할 계정을 만들어주세요.</p>
                <form onSubmit={handleSubmit}>
                    <div className={compStyle.Spacing__40} />
                    <div>
                        <label htmlFor="id" style={{width: "100%", color: "#666", fontWeight: '700'}}>이메일 </label>
                        <div className={compStyle.Spacing__8} />
                        <input 
                            id="id" 
                            name="id" 
                            type="email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{width: "100%", padding: "8px 16px", border: '1px solid #eee', borderRadius: '10px', background: '#fafafa'}}/>
                    </div>
                    <div className={compStyle.Spacing__24} />
                    <div>
                        <label htmlFor="password" style={{width: "100%", color: "#666", fontWeight: '700'}}>비밀번호 </label>
                        <div className={compStyle.Spacing__8} />
                        <input 
                            id="password" 
                            name="password" 
                            type="password" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{width: "100%", padding: "8px 16px", border: '1px solid #eee', borderRadius: '10px', background: '#fafafa'}}/>
                    </div>
                    <div className={compStyle.Spacing__40} />
                    <button 
                        type='submit'
                        style={{width: '100%', border: 'none', padding: '16px', borderRadius: '16px', background: '#154ccb', color: '#fff', fontSize: '16px', fontWeight: '700'}}
                    >
                        로그인
                    </button>
                </form>
                <div className={compStyle.Spacing__40} />
                <p style={{width: '100%', textAlign: 'center'}}>이미 계정이 있으신가요? <a href='/login' style={{color: '#154ccb', fontWeight: '700'}}>로그인</a></p>
            </div>
        </div>
    )
}

export default Login;