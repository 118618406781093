import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const Transaction = () => {
    const navigate = useNavigate();
    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    // const [chatUserId, setChatUserId] = useState(undefined);
    const partnerType = useParams().partnerType;
    const partnerId = searchParams.get('ptid');
    const chatBotId = searchParams.get('cbid');
    const chatUserId = searchParams.get('cuid') || parseInt(Math.random()*1e9);
    
    sessionStorage.setItem('t', searchParams.get('t'));
    sessionStorage.setItem('ch', searchParams.get('ch'));
    sessionStorage.setItem('fc', searchParams.get('fc'));
    sessionStorage.setItem('initializeState', searchParams.get('i'))
    sessionStorage.setItem('ptid', partnerId);
    sessionStorage.setItem('cbid', chatBotId);
    sessionStorage.setItem('cuid', chatUserId);
    
    

    // useEffect(() => {
    //     handleAuth(partnerId, partnerId)
    //         .then(res => setChatUserId);
    // }, [])
    useEffect(() => {
        let chatUrl;
        const initializeState = JSON.parse(searchParams.get('i'));
        if (partnerType === 'dlst') {
            chatUrl = `/dlst/${chatUserId}`; 
        } else {
            chatUrl = (
                initializeState ?
                chatUrl = `/chat/${partnerType}/${partnerId}/${chatBotId}/${chatUserId}` :
                chatUrl = `/chat/dummy/6718be2310310e41ab5276ef/37/${chatUserId}`
            )
            // cafe24 frontSDK를 이용, 접속한 쇼핑몰 회원 정보 호출 = chatUserId
        }
        navigate(chatUrl);
    }, [])
}

export default Transaction;