import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import './style/styles/global.css';
import ChatDemo from './components/Demo/Chat/ChatDemo';
import ChatCookat from './components/Custom/ChatCookat';
import ChatFoodinko from './components/Custom/ChatFoodinko';
import ChatDailyshot from './components/Custom/ChatDailyshot';
import Auth from './auth/auth';
import { Provider } from 'react-redux';
import store from './redux/store';

import ChatTest from './components/Custom/ChatTest';
import Signup from './components/Common/Signup';
import Login from './components/Common/Login';
import Register from './components/Custom/Register';
import ChatVimil from './components/Custom/ChatVimil';
import { ParseCustomSet } from './components/ParseCustomSet';
import { ModuleTest } from './components/ModuleTest';
import ChatDailyshotSDK from './components/Custom/ChatDailyshotSDK';
import { MockupDLST } from './components/MockupDLST';
import Transaction from './components/Common/Transaction';

const App = () => {
  const randomId = parseInt(Math.random()*1e9);

  const setVh = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
  };
  window.addEventListener('resize', setVh);
  setVh();

  return (
    <BrowserRouter>
      <Provider store={store}>
          <Routes>
            <Route path="/" element={<Navigate to={`/demo/${randomId}/demo_ck`} />} />
            <Route path="/demo" element={<Navigate to={`/demo/${randomId}/demo_ck`} />} />
            <Route path="/demo/:chatUserId/:categoryId" element={<ChatDemo />} />
            <Route path='/ckat' element={<Navigate to={`/ckat/${randomId}`} />} />
            {/* <Route path='/ckat/:chatUserId' element={<ChatCookat />} /> */}
            <Route path='/ckat/:chatUserId' element={<ChatCookat variant='B' />} />
            {/* <Route path='/ckat/:chatUserId/st' element={<ChatCookat variant='A' />} />
            <Route path='/ckat/:chatUserId/st/b' element={<ChatCookat variant='B' />} /> */}
            <Route path='/fdko' element={<Navigate to={`/fdko/${randomId}`} />} />
            <Route path='/fdko/:chatUserId' element={<ChatFoodinko />} />
            <Route path='/dlst' element={<Navigate to={`/dlst/${randomId}`} />} />
            <Route path='/dlst/:chatUserId' element={<ChatDailyshot />} />
            <Route path='/dlst/:chatUserId/st' element={<ChatDailyshot variant='A' />} />
            <Route path='/dlst/:chatUserId/st/b' element={<ChatDailyshot variant='B' />} />
            <Route path='/viml' element={<Navigate to={`/viml/${randomId}`} />} />
            <Route path='/viml/:chatUserId' element={<ChatVimil />} />
            <Route path='/dlst/sdk' element={<Navigate to={`/dlst/sdk/${randomId}`} />} />
            <Route path='/dlst/sdk/:chatUserId' element={<ChatDailyshotSDK />} />
            <Route path="/auth" element={<Auth />} />
            
            <Route path='/register' element={<Register />} />
            <Route path='/customset' element={<ParseCustomSet />} />
            <Route path='/chat/:partnerType/:partnerId/:chatBotId/:chatUserId' element={<ChatTest />}/>
            <Route path='/mockup/dlst' element={<MockupDLST />} />

            <Route path='/chatroute/:partnerType' element={<Transaction />} />

            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />

            <Route path='/moduleTest' element={<ModuleTest />} />
          </Routes>
      </Provider>
    </BrowserRouter>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
